import { SelectButton, SelectButtonChangeEvent, SelectButtonProps } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import './ConfirmedStatus.css';
import { useCallback, useState } from 'react';
import { ConfirmedTypes } from '@/@types';
import { env } from '@/utils/constants';

export type ConfirmedStatusProps = SelectButtonProps & {
  canClickOnHold?: boolean;
  disableCancelled?: boolean;
};

export const ConfirmedStatus = ({
  canClickOnHold = true,
  disableCancelled = true,
  ...props
}: ConfirmedStatusProps) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = useCallback(
    (e: SelectButtonChangeEvent) => {
      if (e.value !== selectedValue) {
        setSelectedValue(e.value);
        props.onChange!(e);
      }
    },
    [props.onChange, setSelectedValue, selectedValue]
  );

  return (
    <div>
      <SelectButton
        {...props}
        options={props.options?.filter((option) => {
          if (option.value === ConfirmedTypes.CANCELLED) {
            return env.APP_FEATURE_FLAGS.IS_TO_ENABLE_CANCELLED_STATUS;
          }

          return true;
        })}
        optionDisabled={(option) =>
          (!canClickOnHold && option.value === ConfirmedTypes.HOLD_CONFIRMED) ||
          (disableCancelled && option.value === ConfirmedTypes.CANCELLED)
        }
        onChange={handleChange}
        className={
          classNames(props.className, 'confirmed-status-select') +
          `${env.APP_FEATURE_FLAGS.IS_TO_ENABLE_CANCELLED_STATUS ? ' cancelled-status-select' : ''}`
        }
      />
    </div>
  );
};
