import classNames from 'classnames';
import { AppointmentWithDate, AppointmentWithResourcesList, Origin } from '@/@types';
import { env } from '@/utils/constants';

export const APPOINTMENT_TYPES_CLASSES = {
  medicationAdminInfusion: [821, 921, 922],
  performanceMovement: [283, 364, 282, 369, 284, 365, 221, 366, 367, 303, 368, 321],
  cardiology: [142, 143, 144, 5, 6, 953],
  nutrition: [561, 562, 641, 642],
  neuro: [188, 185, 464, 201, 202, 201, 461, 462, 186, 463, 187, 182, 341, 181, 184, 861, 1041],
  endocrinology: [581, 601, 602, 603],
  gynecology: [21, 22, 23, 741, 742, 841, 842, 843, 844, 845, 846, 847, 848],
  mri: [122],
  mri3T: [942],
  mri15T: [941],
  ultrasound: [127, 1001],
  ct: [121],
  dexa: [123],
  xray: [125],
  mammo: [124],
  integratMed: [721, 722, 723, 724, 725, 726, 727, 728, 943, 944],
  memberLunch: [130],
  steamCellProgram: [501],
  gi: [901, 902, 903, 904],
};

export const getAppointmentTypeColor = (appointment: AppointmentWithDate) => {
  const [className] =
    Object.entries({
      googleEvent: appointment.origin === Origin.Google,
      athenaEvent: appointment.origin === Origin.Athena,
      ...getAppointmentClassObject(appointment),
    }).find(([, value]) => !!value) ?? [];

  return {
    mammoEvent: '#17F4F4',
    cardiologyEvent: '#ECD3CE',
    nutritionEvent: '#FBBD70',
    neuroEvent: '#C3D7F9',
    endocrinologyEvent: '#FDF3D0',
    ctEvent: '#DCE3EF',
    ultrasoundEvent: '#C9F2CE',
    mriEvent: '#FAF199',
    mri3TEvent: '#F3CB00',
    giEvent: '#B3B7FF',
    mri15TEvent: '#FAF199',
    dexaEvent: '#E2D9F1',
    xrayEvent: '#D9D9D9',
    performanceMovementEvent: '#F4B4F8',
    integratMed: '#CEE994',
    steamCellProgram: '#A3EE27',
    memberLunch: '#506653',
    googleEvent: '#757575',
    athenaEvent: '#231f20',
    medicationAdminInfusion: '#A3EE27',
    gynecologyEvent: '#9EF4F4',
  }[className ?? ''];
};

const getAppointmentClassObject = (appointment: { typeId?: number | null; origin: Origin }) =>
  appointment.typeId &&
  appointment.origin === Origin.Atria && {
    performanceMovementEvent: APPOINTMENT_TYPES_CLASSES.performanceMovement.includes(
      appointment.typeId
    ),
    cardiologyEvent: APPOINTMENT_TYPES_CLASSES.cardiology.includes(appointment.typeId),
    nutritionEvent: APPOINTMENT_TYPES_CLASSES.nutrition.includes(appointment.typeId),
    neuroEvent: APPOINTMENT_TYPES_CLASSES.neuro.includes(appointment.typeId),
    endocrinologyEvent: APPOINTMENT_TYPES_CLASSES.endocrinology.includes(appointment.typeId),
    gynecologyEvent: APPOINTMENT_TYPES_CLASSES.gynecology.includes(appointment.typeId),
    mriEvent: APPOINTMENT_TYPES_CLASSES.mri.includes(appointment.typeId),
    mri3TEvent: APPOINTMENT_TYPES_CLASSES.mri3T.includes(appointment.typeId),
    mri15TEvent: APPOINTMENT_TYPES_CLASSES.mri15T.includes(appointment.typeId),
    ultrasoundEvent: APPOINTMENT_TYPES_CLASSES.ultrasound.includes(appointment.typeId),
    ctEvent: APPOINTMENT_TYPES_CLASSES.ct.includes(appointment.typeId),
    dexaEvent: APPOINTMENT_TYPES_CLASSES.dexa.includes(appointment.typeId),
    xrayEvent: APPOINTMENT_TYPES_CLASSES.xray.includes(appointment.typeId),
    mammoEvent: APPOINTMENT_TYPES_CLASSES.mammo.includes(appointment.typeId),
    integratMed: APPOINTMENT_TYPES_CLASSES.integratMed.includes(appointment.typeId),
    memberLunch: APPOINTMENT_TYPES_CLASSES.memberLunch.includes(appointment.typeId),
    steamCellProgram: APPOINTMENT_TYPES_CLASSES.steamCellProgram.includes(appointment.typeId),
    medicationAdminInfusion: APPOINTMENT_TYPES_CLASSES.medicationAdminInfusion.includes(
      appointment.typeId
    ),
  };

export const getPatientVisitClasses = () => ({
  className: 'event-wrapper rbc-background-event bg-zinc',
});

export const getSelectedEventClasses = () => 'bg-gray-200 text-[var(--kelp)]';

export const getAppointmentCardClasses = (appointment: AppointmentWithResourcesList) => {
  return {
    className: classNames('event-wrapper', {
      conflictEvent:
        env.APP_FEATURE_FLAGS.IS_TO_SHOW_APPOINTMENTS_CONFLICTS && appointment.hasConflict,
      external: appointment.externalClient,
      ...([Origin.Atria].includes(appointment.origin) && {
        appointmentConfirmed: appointment.confirmed,
        appointmentNotConfirmed: !appointment.confirmed,
      }),
      ...(appointment.typeId && getAppointmentClassObject(appointment)),
      googleEvent: appointment.origin === Origin.Google,
      athenaEvent: appointment.origin === Origin.Athena,
    }),
  };
};
