function search(source: string, target: string) {
  if (!source || !target) return;
  const left = source.toUpperCase();
  const right = target.toUpperCase();
  return left.indexOf(right) > -1;
}
const toUpCamelCase = (str: string) =>
  str
    .replace('_', ' ')
    .replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const StringHelper = {
  search,
  toUpCamelCase,
  capitalizeFirstLetter,
};
