import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { CalendarSettingsDrawer as CalendarViewSettingsDrawer } from '@/features/Calendar/components/CalendarSettingsDrawer';
import { useSchedulerSocket } from '@/features/Calendar/hooks';

import {
  AppointmentParsed,
  AppointmentType,
  AppointmentWithDate,
  Atria,
  Cmo,
  ConfirmedTypes,
  DailyNotes,
  FiltersProp,
  Origin,
  PatientOption,
  PatientVisit,
  Personnel,
  Provider,
  ProviderEquipment,
  ProviderRoom,
  Resources,
  Room,
  RoomType,
  SelectOption,
  VisitTypeTemplates,
  isPatientVisit,
} from '@/@types';
import {
  Calendar,
  ChangeEvent,
  DailyNotesDrawer,
  DragStartEvent,
  PatientVisitDetails,
  SchedulerFilters,
} from '@/components';
import { Permission, useAuthContext, useToastContext, useLocation } from '@/contexts';
import { DateTimeHelper, OrderColumnsHelper, SchedulerHelper, WindowHelper } from '@/helpers';
import {
  ActionHistoryType,
  useActionHistory,
  useDailyNotes,
  useGoogleCalendar,
  useScheduler,
  useSchedulerDragDrop,
  useSchedulerResize,
  useSchedulerSelecting,
  useSchedulerViewSettings,
} from '@/hooks';
import { parseAppointments } from '@/utils';

import { AppointmentsDeletedHistoryDrawer } from '@/features/AppointmentActions/components/AppointmentsDeletedHistoryDrawer';
import { env } from '@/utils/constants';
import MobileDetect from 'mobile-detect';
import './Scheduler.css';
import { Sidebar } from 'primereact/sidebar';
import classNames from 'classnames';
import { useAppointmentActions } from '@/features/AppointmentActions';
import {
  defaultDailyNoteTitle,
  standardDailyNoteTitles,
} from '../DailyNotesDrawer/DailyNotesDrawer';

type SelectSlotProps = {
  start: Date;
  end: Date;
  resourceId: string;
  action: 'select' | 'click' | 'doubleClick';
  slots: Date[];
};

type Props = {
  showFilters: boolean;
  setShowFilters: (show: boolean) => void;
  filters: FiltersProp;
  setFilterItem: (key: keyof FiltersProp, value: any) => void;
  providersOptionsList: Provider[];
  roomsOptionsList: Room[];
  equipmentFilterList: ProviderEquipment[];
  providersFilterList: Personnel[];
  appointmentTypesList: AppointmentType[];
  cmosFilterList: Cmo[];
  isViewingDefaultView: boolean;
  visitTypeTemplates: VisitTypeTemplates[];
  showAppointmentsDeletedHistory: boolean;
  setShowAppointmentsDeletedHistory: (show: boolean) => void;
};

export const Scheduler = ({
  showFilters,
  filters,
  setFilterItem,
  providersOptionsList,
  equipmentFilterList,
  providersFilterList,
  roomsOptionsList,
  cmosFilterList,
  setShowFilters,
  isViewingDefaultView,
  appointmentTypesList,
  visitTypeTemplates,
  showAppointmentsDeletedHistory,
  setShowAppointmentsDeletedHistory,
}: Props) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  const {
    onAppointmentDetailsClick,
    onAppointmentCreateClick,
    onAppointmentEditClick,
    onAppointmentDuplicateClick,
    onAppointmentDeleteClick,
  } = useAppointmentActions();

  const { addNewAction } = useActionHistory();
  const [showPatientVisitDetails, setShowPatientVisitDetails] = useState(false);
  const [appointmentsSelected, setAppointmentsSelected] = useState<AppointmentWithDate[]>([]);
  const [patientVisitAppointments, setPatientVisitAppointments] = useState<AppointmentWithDate[]>(
    []
  );
  const [dailyNotes, setDailyNotes] = useState<DailyNotes[]>([]);
  const [selectedDailyNote, setSelectedDailyNote] = useState<Partial<DailyNotes> | null>(null);
  const [resources, setResources] = useState<Resources[]>([]);
  const [allAppointments, setAllAppointments] = useState<Atria.Appointment[]>([]);
  const [patientOptions, setPatientOptions] = useState<PatientOption[]>([]);
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentWithDate | undefined>();
  const [patientVisitDetails, setPatientVisitDetails] = useState<PatientVisit | undefined>();
  const [dragAppointment, setDragAppointment] = useState<AppointmentWithDate | undefined>();
  const { hasPermission } = useAuthContext();
  const {
    findDailyNotesByDate,
    createDailyNotes,
    updateDailyNote,
    deleteDailyNote,
    showEditDailyNotes,
    setShowEditDailyNotes,
  } = useDailyNotes();
  const { getAppointmentsByDay, updateAppointment } = useScheduler();
  const { googleCalendarAppointments, eventsOOOAndAllDay, findProvidersEventsOnGoogleCalendar } =
    useGoogleCalendar();
  const { toast } = useToastContext();
  const { showCalendarViewSettings, setShowCalendarViewSettings, ...viewSettings } =
    useSchedulerViewSettings();

  const {
    updateMultipleAppointmentsOnDrop,
    handleDraggableAccessor,
    updatePatientVisitAppointmentsOnDrop,
  } = useSchedulerDragDrop({
    appointmentsSelected,
    providersOptionsList,
    roomsOptionsList,
    patientVisitAppointments,
  });

  const { updateAppointmentByResizing, handleResizableAccessor } = useSchedulerResize();
  const { selectedRegion } = useLocation();

  const uniqueAppointmentsSelected = useMemo(
    () =>
      appointmentsSelected.reduce((all, current) => {
        const appt = all.find((a) => a.id === current.id);
        if (!appt) {
          all.push(current);
        }
        return all;
      }, [] as AppointmentWithDate[]),
    [appointmentsSelected]
  );

  const regionToUse = useMemo(
    () => (selectedRegion?.id || filters.location)!,
    [selectedRegion, filters.location]
  );

  const [currentDate, setCurrentDate] = useState<Date>(
    env.APP_FEATURE_FLAGS.IS_TO_USE_FIXED_TIME_AND_TIMEZONE_ON_DATE_PICKER_CHANGE
      ? new Date(DateTimeHelper.changeFormatmmDDYYYYToYYYYDDmm(filters.date) + 'T04:04:00-04:00')
      : DateTime.fromFormat(filters.date, 'MM-dd-yyyy').toJSDate()
  );

  useSchedulerSocket(setAllAppointments, currentDate, toast);

  const suiteRooms = useMemo(() => {
    return roomsOptionsList.filter((r) => r.isSuite).map((r) => r.id);
  }, [roomsOptionsList]);

  const allCalendarAppointments = useMemo(() => {
    return [...parseAppointments(allAppointments, suiteRooms)];
  }, [allAppointments, suiteRooms]);

  const appointmentsToBackgroundEvents = useMemo(() => {
    if (!viewSettings.showAthenaAppointments) {
      return allAppointments.filter((apt) => apt.atriaAppointment);
    }
    return allAppointments;
  }, [allAppointments, viewSettings.showAthenaAppointments]);

  const appointmentsGroupByPatientId = useMemo(() => {
    return SchedulerHelper.groupAppointmentsByPatientId(appointmentsToBackgroundEvents);
  }, [appointmentsToBackgroundEvents]);

  const appointmentsGroupByPatientIdOnlyForRooms = useMemo(() => {
    return SchedulerHelper.groupAppointmentsByPatientId(
      appointmentsToBackgroundEvents.filter((appt) => appt.rooms.length)
    );
  }, [appointmentsToBackgroundEvents]);

  const googleCalendarAppointmentsWithoutAllDayEvents = useMemo(
    () => googleCalendarAppointments.filter((event) => !event.isAllDay),
    [googleCalendarAppointments]
  );

  const googleAppointmentsByProviderId = useMemo(
    () =>
      SchedulerHelper.getGoogleAppointmentsByProviderId(
        googleCalendarAppointmentsWithoutAllDayEvents
      ),
    [googleCalendarAppointmentsWithoutAllDayEvents]
  );

  const memoPatientVisits = useMemo(() => {
    const patientVisitsForRooms = SchedulerHelper.getAppointmentsPerPatientId(
      appointmentsGroupByPatientIdOnlyForRooms
    );
    const patientVisitsForAllResources = SchedulerHelper.getAppointmentsPerPatientId(
      appointmentsGroupByPatientId
    );
    const patientVisitsList: PatientVisit[] = [];
    patientVisitsList.push(
      ...SchedulerHelper.createBackgroundEventsForPatientAppointments({
        patientVisitsForRooms,
        patientVisitsForAllResources,
        resources,
        personnel: providersFilterList,
        hidePatientName: viewSettings.hidePatientName,
      })
    );
    // SHOW GOOGLE CALENDAR EVENTS
    if (
      viewSettings.showGoogleCalendarEvents &&
      SchedulerHelper.checkIfResourcesContains(resources, 'provider')
    ) {
      patientVisitsList.push(
        ...SchedulerHelper.createBackgroundEventsForGoogleAppointments(
          googleAppointmentsByProviderId
        )
      );
    }
    return patientVisitsList;
  }, [
    appointmentsGroupByPatientId,
    appointmentsGroupByPatientIdOnlyForRooms,
    resources,
    googleAppointmentsByProviderId,
    viewSettings.showGoogleCalendarEvents,
    providersFilterList,
    viewSettings.hidePatientName,
  ]);

  const calendarResources = useMemo(() => {
    const roomsWithAppointments = SchedulerHelper.getAppointmentsGroupedByRoom(allAppointments);

    if (viewSettings.showEmptyRooms || !isViewingDefaultView || !roomsWithAppointments.size) {
      return resources;
    }

    return resources.filter((resource) => roomsWithAppointments.has(resource.resourceTitle));
  }, [allAppointments, isViewingDefaultView, resources, viewSettings.showEmptyRooms]);

  const handleDailyNotesClick = useCallback(
    (index: number, dailyNote?: DailyNotes) => {
      setSelectedDailyNote(
        dailyNote ?? { title: standardDailyNoteTitles[index] || defaultDailyNoteTitle }
      );
      setShowEditDailyNotes(true);
    },
    [setSelectedDailyNote, setShowEditDailyNotes]
  );

  const handleDailyNotesChange = useCallback(
    async (newDailyNotes: string, title: string, id?: number) => {
      if (env.APP_FEATURE_FLAGS.IS_TO_ENABLE_MULTIPLE_DAILY_NOTES) {
        const note = id
          ? await updateDailyNote(id, title, newDailyNotes)
          : await createDailyNotes(currentDate, title, newDailyNotes, regionToUse);

        if (!note) return;

        const currentNotes = dailyNotes.map((daily) => (daily.id !== note?.id ? daily : note));

        setDailyNotes([...currentNotes, ...(id ? [] : [note])]);

        return;
      }

      const updatedNotes = await createDailyNotes(currentDate, title, newDailyNotes, regionToUse);

      if (!updatedNotes) return;

      setDailyNotes([updatedNotes]);
    },
    [regionToUse, createDailyNotes, updateDailyNote, currentDate, dailyNotes]
  );

  const handleDailyNotes = useCallback(
    async (date: Date) => {
      if (regionToUse) {
        const foundDailyNotes = await findDailyNotesByDate(date, regionToUse);
        setDailyNotes(foundDailyNotes);
      }
    },
    [findDailyNotesByDate, regionToUse]
  );

  const addNewPatientsOptions = useCallback(
    (appointments: AppointmentWithDate[]) => {
      const uniqueIds: number[] = [];
      const newPatientOptions: PatientOption[] = [];
      setFilterItem('patients', []);

      appointments.forEach((appointment) => {
        if (appointment.patientId && !uniqueIds.includes(appointment.patientId)) {
          uniqueIds.push(appointment.patientId);
          newPatientOptions.push({
            patientId: appointment.patientId,
            patientName: appointment.patientName!,
            resourceId: `patient-${appointment.patientId}`,
            resourceTitle: appointment.patientName!,
          });
        }
      });

      setPatientOptions(newPatientOptions);
    },
    [setFilterItem]
  );

  const handleAppointmentsPerDay = useCallback(
    async (date: Date) => {
      try {
        const appointments = await getAppointmentsByDay(
          DateTime.fromJSDate(date).startOf('day').toUTC().toString(),
          DateTime.fromJSDate(date).endOf('day').toUTC().toString()
        );

        setAllAppointments(appointments);
        addNewPatientsOptions([...parseAppointments(appointments, suiteRooms)]);
      } catch {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed to fetch appointments',
          life: 3000,
        });
      }
    },
    [addNewPatientsOptions, getAppointmentsByDay, suiteRooms, toast]
  );

  const handleOnResourcesChange = useCallback((values: Resources[]) => {
    return setResources(OrderColumnsHelper.order(values));
  }, []);

  const isClickingBackgroundEvent = useCallback(
    (slotProps: SelectSlotProps): boolean => {
      if (!viewSettings.showPatientBackgroundEvents || slotProps.action !== 'click') {
        return false;
      }

      const {
        resourceId,
        slots: [start, end],
      } = slotProps;

      const sameResourceAndTimeBackgroundEvents = memoPatientVisits.filter(
        (visit) =>
          visit.resourceId === resourceId &&
          DateTime.fromJSDate(visit.date!) <= DateTime.fromJSDate(start) &&
          DateTime.fromJSDate(visit.end!) >= DateTime.fromJSDate(end)
      );

      return sameResourceAndTimeBackgroundEvents.length > 0;
    },
    [memoPatientVisits, viewSettings.showPatientBackgroundEvents]
  );

  const handleSelectSlot = useCallback(
    (slotProps: SelectSlotProps) => {
      if (isClickingBackgroundEvent(slotProps)) {
        return;
      }

      if (slotProps.resourceId.startsWith('cmo') || !hasPermission(Permission.CREATE_APPOINTMENT))
        return;

      onAppointmentCreateClick({
        start: slotProps.start,
        end: slotProps.end,
        resources: resources.filter((r) => r.resourceId == slotProps.resourceId),
      });
    },
    [hasPermission, isClickingBackgroundEvent, onAppointmentCreateClick, resources]
  );

  const handleSelectAllSameColumn = useCallback(() => {
    setAppointmentsSelected((prevApptsSelected) => {
      const resourceIds = prevApptsSelected.map((appt) => appt.resourceId);
      const allSameResourceId = allCalendarAppointments.filter((appt) => {
        if (!appt.resourceId) return;
        return resourceIds.includes(appt.resourceId);
      });
      return allSameResourceId;
    });
  }, [allCalendarAppointments]);

  const handleSelectAllRooms = useCallback(
    (params?: { atriaOnly?: boolean }): Promise<AppointmentWithDate[]> => {
      return new Promise((resolve) => {
        setAppointmentsSelected((prevApptsSelected) => {
          let prevAppointmentsCopy = [...prevApptsSelected];
          if (params?.atriaOnly) {
            prevAppointmentsCopy = [...prevApptsSelected].filter((appt) => appt.atriaAppointment);
          }
          const apptsSelectedIds = new Set([...prevAppointmentsCopy].map(({ id }) => id));
          const allApptsWithSameId = allCalendarAppointments.filter((appt) => {
            if (!appt.resourceId) return;
            const isSelected = apptsSelectedIds.has(appt.id);
            if (appt.atriaAppointment) {
              return isSelected;
            }
            const isRoom = appt.resourceId.startsWith('room');
            return isSelected && isRoom;
          });
          resolve(allApptsWithSameId);
          return allApptsWithSameId;
        });
      });
    },
    [allCalendarAppointments]
  );

  const handleDuplicateAppointmentsOnSubmit = useCallback(() => {
    setAppointmentsSelected([]);
    setAppointmentDetails(undefined!);
  }, []);

  const handleDuplicateAppointmentsClick = useCallback(() => {
    onAppointmentDuplicateClick(uniqueAppointmentsSelected, handleDuplicateAppointmentsOnSubmit);
  }, [
    onAppointmentDuplicateClick,
    uniqueAppointmentsSelected,
    handleDuplicateAppointmentsOnSubmit,
  ]);

  const parseNewAppointment = useCallback(
    async (newAppointments?: Atria.Appointment[]) => {
      try {
        loader.show();
        if (newAppointments) {
          setAllAppointments((prev) => {
            const notIn = newAppointments.filter(
              (x) => !prev.map((a) => a.appointmentId).includes(x.appointmentId)
            );

            return [...prev, ...notIn];
          });
        } else {
          await handleAppointmentsPerDay(currentDate);
        }
      } finally {
        loader.hide();
      }
    },
    [currentDate, handleAppointmentsPerDay]
  );

  const handleAppointmentUpdate = useCallback(() => {
    setAppointmentsSelected([]);
  }, []);

  const handleAppointmentEditClick = useCallback(() => {
    onAppointmentEditClick(
      uniqueAppointmentsSelected?.length > 0 ? uniqueAppointmentsSelected : appointmentDetails,
      handleAppointmentUpdate
    );
  }, [
    appointmentDetails,
    handleAppointmentUpdate,
    onAppointmentEditClick,
    uniqueAppointmentsSelected,
  ]);

  const handleConfirmAppointmentDelete = useCallback(async () => {
    const isMultipleDeletion = uniqueAppointmentsSelected.length > 0;
    if (isMultipleDeletion) {
      setAppointmentsSelected([]);
    } else {
      setAppointmentDetails(undefined);
    }
  }, [uniqueAppointmentsSelected]);

  const onDelete = useCallback(async () => {
    const onlyAtriaAppointments = await handleSelectAllRooms({ atriaOnly: true });
    if (onlyAtriaAppointments.length) {
      onAppointmentDeleteClick(uniqueAppointmentsSelected, handleConfirmAppointmentDelete);
    }
  }, [
    handleConfirmAppointmentDelete,
    handleSelectAllRooms,
    onAppointmentDeleteClick,
    uniqueAppointmentsSelected,
  ]);

  const roomsOptionsAsResources: ProviderRoom[] = useMemo(
    () =>
      roomsOptionsList
        .map(({ name, location, id, defaultView, displayOrder, type }) => ({
          resourceId: `room-${id}`,
          resourceTitle: name,
          prefix: 'room',
          locationId: location.id,
          defaultView,
          type: RoomType[type as keyof typeof RoomType],
          displayOrder: displayOrder,
        }))
        .sort((a, b) => a.displayOrder - b.displayOrder),
    [roomsOptionsList]
  );

  const roomsDefaultView = useMemo(() => {
    return roomsOptionsAsResources.filter((r) => r.defaultView);
  }, [roomsOptionsAsResources]);

  const handleAppointmentCreateClick = useCallback(async () => {
    const selectedResources = isViewingDefaultView ? [] : resources;
    onAppointmentCreateClick({
      start: currentDate,
      resources: selectedResources,
    });
  }, [currentDate, isViewingDefaultView, onAppointmentCreateClick, resources]);

  const handleAppointmentsCreateFromTemplate = useCallback(async () => {
    parseNewAppointment();
  }, [parseNewAppointment]);

  const handleAppointmentUpdated = useCallback(async (updatedAppointment: Atria.Appointment) => {
    setAllAppointments((prev) => {
      const copy = [...prev].filter((appt) => appt.id !== updatedAppointment.id);
      return [...copy, updatedAppointment];
    });
  }, []);

  const handleOnDragStart = useCallback(({ event: appointment }: DragStartEvent) => {
    if (isPatientVisit(appointment)) {
      setPatientVisitAppointments(appointment.appointments!);
    } else {
      setDragAppointment(appointment);
    }
  }, []);

  const updateAtriaAppointmentOnDrop = useCallback(
    ({ start: date, end, resourceId, event: appointment }: ChangeEvent) => {
      if (!isPatientVisit(appointment)) {
        let body: AppointmentWithDate = { ...appointment, date, end };
        const isRoom = resourceId.startsWith('room');
        if (isRoom) {
          const roomId = Number(resourceId.replace('room-', ''));
          const previousRoomId = Number(dragAppointment?.resourceId?.replace('room-', ''));
          const appointmentType = appointmentTypesList.find(
            (type) => type.id === appointment.typeId
          );

          const roomsWithoutPreviousRoom =
            appointment.rooms?.filter((r) => r.id !== previousRoomId) || [];

          const newRoom = roomsOptionsList.find(({ id }) => id === roomId)!;

          if (
            appointmentType?.defaultRooms?.some(
              (room) => room.roomId === previousRoomId && room.restricted === true
            ) &&
            !SchedulerHelper.cancellationRooms.includes(newRoom.id)
          ) {
            return;
          }

          const rooms = [...roomsWithoutPreviousRoom, { id: newRoom.id, label: newRoom.name }];

          if (appointment.typeId) {
            const newRoomsSelected = SchedulerHelper.getRelatedNewRooms(
              [{ id: newRoom.id, label: newRoom.name }],
              roomsOptionsList.map((er) => ({ id: er.id, label: er.name })),
              appointmentType
            );

            rooms.push(...newRoomsSelected);

            if (SchedulerHelper.cancellationRooms.includes(previousRoomId)) {
              const appointmentTypeDefaultRooms = SchedulerHelper.getAppointmentTypeDefaultRooms(
                [{ id: newRoom.id, label: newRoom.name }],
                roomsOptionsList.map((er) => ({ id: er.id, label: er.name })),
                appointmentTypesList.find((type) => type.id === appointment.typeId)
              );

              rooms.push(...appointmentTypeDefaultRooms);
            }
          }

          body = {
            ...body,
            rooms: rooms.reduce((acc: SelectOption[], current: SelectOption) => {
              if (!acc.find((obj) => obj.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []),
          };
        }
        const isProvider = resourceId.startsWith('provider');
        if (isProvider) {
          const provider = providersOptionsList.find(
            ({ id }) => id === Number(resourceId.replace('provider-', ''))
          )!;
          body = {
            ...body,
            resourceId: resourceId,
            provider: { id: provider.id, label: provider.name },
          };
        }
        return body;
      }
    },
    [dragAppointment?.resourceId, providersOptionsList, roomsOptionsList, appointmentTypesList]
  );

  const updateAthenaAppointmentOnDrop = useCallback(
    ({ resourceId, event: appointment }: ChangeEvent) => {
      if (!isPatientVisit(appointment)) {
        let body: AppointmentWithDate | undefined;
        const isRoom = resourceId.startsWith('room');
        if (isRoom) {
          let roomsWithoutPreviousRoom: SelectOption[] = [];
          if (dragAppointment?.resourceId && dragAppointment.resourceId.startsWith('room-')) {
            const roomId = Number(dragAppointment.resourceId.replace('room-', ''));
            roomsWithoutPreviousRoom = appointment.rooms?.filter((r) => r.id !== roomId) || [];
          }
          const roomId = Number(resourceId.replace('room-', ''));
          const newRoom = roomsOptionsList.find(({ id }) => id === roomId)!;
          const rooms = [...roomsWithoutPreviousRoom, { id: newRoom.id, label: newRoom.name }];

          if (appointment.typeId) {
            const newRoomsSelected = SchedulerHelper.getRelatedNewRooms(
              [{ id: newRoom.id, label: newRoom.name }],
              roomsOptionsList.map((er) => ({ id: er.id, label: er.name })),
              appointmentTypesList.find((type) => type.id === appointment.typeId)
            );

            rooms.push(...newRoomsSelected);
          }

          body = {
            ...appointment,
            rooms: rooms.reduce((acc: SelectOption[], current: SelectOption) => {
              if (!acc.find((obj) => obj.id === current.id)) {
                acc.push(current);
              }
              return acc;
            }, []),
          };
        }
        return body;
      }
    },
    [dragAppointment?.resourceId, roomsOptionsList, appointmentTypesList]
  );

  const updateAppointmentOnDrop = useCallback(
    async (data: ChangeEvent) => {
      if (!isPatientVisit(data.event)) {
        const { event: appointment } = data;
        let body: AppointmentWithDate | undefined;
        if (appointment.atriaAppointment) {
          body = updateAtriaAppointmentOnDrop(data);
        } else {
          body = updateAthenaAppointmentOnDrop(data);
        }
        if (body) {
          await updateAppointment(appointment.id, body);
        }
      }
    },
    [updateAppointment, updateAthenaAppointmentOnDrop, updateAtriaAppointmentOnDrop]
  );

  const patientVisitDropFilters = useCallback((data: ChangeEvent) => {
    const { resourceId, event: appointment } = data;
    if (isPatientVisit(appointment)) {
      const newResourceType = resourceId.split('-')[0];
      const currentResourceType = appointment?.resourceId?.split('-')[0];
      const notCompatibleChange = currentResourceType !== newResourceType;

      return notCompatibleChange;
    }
  }, []);

  const handlePatientVisitDrop = useCallback(
    async (data: ChangeEvent) => {
      const isInvalid = patientVisitDropFilters(data);
      if (isInvalid) {
        return;
      }

      loader.show();
      await updatePatientVisitAppointmentsOnDrop(data);
      await handleAppointmentsPerDay(currentDate);
      setPatientVisitAppointments([]);
      loader.hide();
    },
    [
      currentDate,
      handleAppointmentsPerDay,
      patientVisitDropFilters,
      updatePatientVisitAppointmentsOnDrop,
    ]
  );

  const eventDropFilters = useCallback(
    (data: ChangeEvent) => {
      if (!isPatientVisit(data.event)) {
        const { resourceId, start, event: appointment } = data;
        const dragResourceType = dragAppointment?.resourceId?.split('-')[0];
        const resourceType = resourceId.split('-')[0];
        const notCompatibleChange = dragResourceType !== resourceType;
        const changingDateOfAthenaAppointment =
          !appointment.atriaAppointment &&
          !DateTime.fromJSDate(start).equals(DateTime.fromJSDate(appointment.date));
        const isPatientVisitEvent = isPatientVisit(appointment);
        const isInvalid =
          notCompatibleChange || changingDateOfAthenaAppointment || isPatientVisitEvent;
        return isInvalid;
      }
    },
    [dragAppointment?.resourceId]
  );

  const handleOnEventDrop = useCallback(
    async (data: ChangeEvent) => {
      if (isPatientVisit(data.event)) {
        handlePatientVisitDrop(data);
        return;
      }

      try {
        const isInvalid = eventDropFilters(data);
        if (isInvalid) return;
        loader.show();
        const isMultipleUpdating = appointmentsSelected.length > 0;

        if (isMultipleUpdating) {
          await updateMultipleAppointmentsOnDrop(data);
        } else {
          await updateAppointmentOnDrop(data);
        }

        addNewAction({
          type: isMultipleUpdating
            ? ActionHistoryType.UPDATE_MULTIPLE_APPOINTMENTS
            : ActionHistoryType.UPDATE_APPOINTMENT,
          data: {
            ...data,
            providersOptionsList,
            roomsOptionsList,
            appointmentsSelected,
          },
        });
      } catch (error: any) {
        const detail =
          error?.response?.data?.messages?.[0]?.message ||
          error?.response?.data?.message ||
          'Failed to update multiple appointments';
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail,
          life: 3000,
        });
      } finally {
        await handleAppointmentsPerDay(currentDate);
        setAppointmentsSelected([]);
        loader.hide();
      }
    },
    [
      handlePatientVisitDrop,
      eventDropFilters,
      appointmentsSelected,
      addNewAction,
      providersOptionsList,
      roomsOptionsList,
      updateMultipleAppointmentsOnDrop,
      updateAppointmentOnDrop,
      toast,
      handleAppointmentsPerDay,
      currentDate,
    ]
  );

  const handleCurrentDateChange = useCallback((e: Date) => {
    setCurrentDate(e);
  }, []);

  const handleOnPatientVisitAppointmentsEdit = useCallback(
    (appointments: AppointmentParsed[]) => {
      setShowPatientVisitDetails(false);
      onAppointmentEditClick(appointments, handleAppointmentUpdate);
    },
    [handleAppointmentUpdate, onAppointmentEditClick]
  );

  const calendarAppointments = useMemo(() => {
    let result = [...allCalendarAppointments];
    if (!viewSettings.showAthenaAppointments) {
      result = result.filter((i: any) => i?.origin !== Origin.Athena);
    }
    if (viewSettings.showGoogleCalendarEvents) {
      result = [...result, ...(googleCalendarAppointments as any)];
    }
    if (!viewSettings.showUnconfirmedAppointments) {
      result = result.filter(
        (i: AppointmentWithDate) =>
          i.confirmed !== ConfirmedTypes.UNCONFIRMED && i.origin === Origin.Atria
      );
    }
    return result;
  }, [
    allCalendarAppointments,
    googleCalendarAppointments,
    viewSettings.showAthenaAppointments,
    viewSettings.showGoogleCalendarEvents,
    viewSettings.showUnconfirmedAppointments,
  ]);

  useEffect(() => {
    setFilterItem('date', DateTimeHelper.returnOnlyDateMMddYYYY(currentDate));
  }, [currentDate, setFilterItem]);

  useEffect(() => {
    async function loadInitialState() {
      loader.show();
      try {
        await Promise.all([handleDailyNotes(currentDate), handleAppointmentsPerDay(currentDate)]);
        loader.hide();
      } catch (error) {
        toast?.current?.show({
          severity: 'error',
          summary: 'Failed to load Appointments/Daily notes',
          detail: `Reloading the page in 5 seconds. If error persists, message the #product-support channel in Slack`,
          life: 60000,
        });
      }
    }
    if (filters.stateInitialized && regionToUse) {
      loadInitialState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.date, filters.stateInitialized, regionToUse]);

  useEffect(() => {
    if (resources.length === 0) {
      setResources(roomsDefaultView);
    }
  }, [currentDate, resources, roomsDefaultView]);

  useEffect(() => {
    handleOnResourcesChange([
      ...filters.personnel,
      ...filters.rooms,
      ...filters.equipment,
      ...filters.cmos,
      ...filters.patients,
    ]);
  }, [
    filters.cmos,
    filters.equipment,
    filters.patients,
    filters.personnel,
    filters.rooms,
    handleOnResourcesChange,
  ]);

  useEffect(() => {
    if (filters.stateInitialized) {
      findProvidersEventsOnGoogleCalendar(filters.date, filters.personnel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.date, filters.personnel, filters.stateInitialized]);

  const { handleSelectIndividualEvents, handleSelectMultipleEvents } = useSchedulerSelecting({
    selectedEvents: appointmentsSelected,
    appointments: calendarAppointments,
    onEventSelect: setAppointmentsSelected,
  });

  const handleSelectEvent = useCallback(
    async (appointment: AppointmentWithDate | PatientVisit, e: React.MouseEvent) => {
      const isKeyPressed = WindowHelper.isMac() ? e.metaKey : e.ctrlKey;
      const isShiftPressed = e.shiftKey;
      if (isShiftPressed && !isPatientVisit(appointment)) {
        return handleSelectMultipleEvents(appointment);
      }
      if (isKeyPressed && !isPatientVisit(appointment)) {
        return handleSelectIndividualEvents(appointment);
      }
      if (isPatientVisit(appointment)) {
        setPatientVisitDetails(appointment);
        setShowPatientVisitDetails(true);
        return;
      }
      const googleEventTypeId = 0;
      const isGoogleCalendarAppointment = appointment.typeId === googleEventTypeId;

      if (isGoogleCalendarAppointment && appointment.conferenceLink) {
        return window.open(appointment.conferenceLink, '_blank', 'noopener,noreferrer');
      }

      if (isGoogleCalendarAppointment) {
        return;
      }
      onAppointmentDetailsClick(appointment);
    },
    [handleSelectIndividualEvents, handleSelectMultipleEvents, onAppointmentDetailsClick]
  );

  const hideDailyNoteDrawer = useCallback(() => {
    setShowEditDailyNotes(false);
    setSelectedDailyNote(null);
  }, [setShowEditDailyNotes, setSelectedDailyNote]);

  const handleDailyNoteDelete = useCallback(
    async (id?: number) => {
      if (!id) return;

      const success = await deleteDailyNote(id);

      if (success) setDailyNotes(dailyNotes.filter((note) => note.id !== id));

      hideDailyNoteDrawer();
    },
    [dailyNotes, deleteDailyNote, hideDailyNoteDrawer]
  );

  return (
    <>
      <motion.aside
        variants={{
          open: { x: 0 },
          closed: { x: -296 },
        }}
        transition={{ damping: 40 }}
        animate={showFilters ? 'open' : 'closed'}
        className={classNames('absolute w-screen max-w-[280px] flex flex-col overflow-y-auto')}
      >
        {isMobile ? (
          <Sidebar visible={showFilters} position='left' onHide={() => setShowFilters(false)}>
            <SchedulerFilters
              roomsOptions={roomsOptionsAsResources}
              patientOptions={patientOptions}
              equipmentFilterList={equipmentFilterList}
              providersFilterList={providersFilterList}
              cmosFilterList={cmosFilterList}
              onResourcesChange={handleOnResourcesChange}
              filters={filters}
              setFilterItem={setFilterItem}
            />
          </Sidebar>
        ) : (
          <SchedulerFilters
            roomsOptions={roomsOptionsAsResources}
            patientOptions={patientOptions}
            equipmentFilterList={equipmentFilterList}
            providersFilterList={providersFilterList}
            cmosFilterList={cmosFilterList}
            onResourcesChange={handleOnResourcesChange}
            filters={filters}
            setFilterItem={setFilterItem}
          />
        )}
      </motion.aside>
      <motion.main
        initial={{ marginLeft: isMobile ? 0 : '288px' }}
        variants={{
          margin: { marginLeft: isMobile ? 0 : '288px' },
          noMargin: { marginLeft: '0px' },
        }}
        transition={{ damping: isMobile ? 0 : 40 }}
        animate={showFilters ? 'margin' : 'noMargin'}
        className='h-full'
      >
        <Calendar
          {...viewSettings}
          location={regionToUse}
          appointments={calendarAppointments}
          selectedEvents={appointmentsSelected}
          eventsOOOAndAllDay={eventsOOOAndAllDay}
          patientVisits={memoPatientVisits}
          currentDate={currentDate}
          dailyNotes={dailyNotes}
          resources={calendarResources}
          roomsOptionsList={roomsOptionsList}
          suiteRooms={suiteRooms}
          onDailyNotesClick={handleDailyNotesClick}
          onCurrentDateChange={handleCurrentDateChange}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          onDragStart={handleOnDragStart}
          onEventDrop={handleOnEventDrop}
          onEventSelect={setAppointmentsSelected}
          onEditByControlPanel={handleAppointmentEditClick}
          onSelectAllSameColumnByControlPanel={handleSelectAllSameColumn}
          onDelete={onDelete}
          onDuplicateByControlPanel={handleDuplicateAppointmentsClick}
          onEventResize={(event) => updateAppointmentByResizing(event, handleAppointmentUpdated)}
          handleResizableAccessor={handleResizableAccessor}
          handleDraggableAccessor={handleDraggableAccessor}
          onNewAppointmentClick={handleAppointmentCreateClick}
          onCalendarViewSettingsClick={() => setShowCalendarViewSettings(true)}
          onAppointmentCreateByTemplateSubmit={handleAppointmentsCreateFromTemplate}
          onAppointmentsDeletedHistoryClick={() => {
            setShowAppointmentsDeletedHistory(true);
          }}
          onToggleFiltersClick={() => setShowFilters(!showFilters)}
          visitTypeTemplates={visitTypeTemplates}
        />
      </motion.main>
      <DailyNotesDrawer
        visible={showEditDailyNotes}
        onHide={hideDailyNoteDrawer}
        currentDate={currentDate}
        selectedDailyNote={selectedDailyNote}
        onDailyNotesSave={handleDailyNotesChange}
        onDelete={handleDailyNoteDelete}
      />
      <CalendarViewSettingsDrawer
        isVisible={showCalendarViewSettings}
        onHide={() => setShowCalendarViewSettings(false)}
        isShowEmptyRoomsDisabled={!isViewingDefaultView}
        {...viewSettings}
        showEmptyRooms={isViewingDefaultView ? viewSettings.showEmptyRooms : true}
      />

      {patientVisitDetails && (
        <PatientVisitDetails
          visible={showPatientVisitDetails}
          onHide={() => setShowPatientVisitDetails(false)}
          patientVisit={patientVisitDetails}
          handleOnEditAppointments={handleOnPatientVisitAppointmentsEdit}
        />
      )}
      <AppointmentsDeletedHistoryDrawer
        visible={showAppointmentsDeletedHistory}
        onHide={() => setShowAppointmentsDeletedHistory(false)}
        locationId={regionToUse}
        {...viewSettings}
      />
    </>
  );
};
