import { Atria } from '@/@types';
import { useAppointmentActions } from '@/features/AppointmentActions';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import GCalIcon from '@/assets/gcal-icon.png';
import AthenaIcon from '@/assets/athena-icon.png';
import { getAppointmentTypeColor } from '@/features/Calendar/utils';
import { mountAppointmentObject } from '@/utils';

type AppointmentItemProps = {
  item: Atria.Appointment;
};

const getContrastYIQ = (hexcolor: string) => {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? 'text-product-forest-100' : 'text-white';
};

export const AppointmentItem = ({ item }: AppointmentItemProps) => {
  const { onAppointmentDetailsClick } = useAppointmentActions();

  const appointmentParsed = useMemo(() => mountAppointmentObject(item), [item]);

  const formatDate = useMemo(() => {
    const startFormatted = DateTime.fromISO(item.date).toFormat('h:mm');
    const endFormatted = DateTime.fromISO(item.end).toFormat('h:mma');

    return `${startFormatted} - ${endFormatted}`.replaceAll(':00', '').toLowerCase();
  }, [item]);

  const handleAppointmentClick = useCallback(() => {
    onAppointmentDetailsClick(appointmentParsed);
  }, [appointmentParsed]);

  const [appointmentTypeColor, textContentColor, textTitleColor] = useMemo(() => {
    const color = getAppointmentTypeColor(appointmentParsed);
    const contentColor = color ? getContrastYIQ(color) : 'text-product-grey-400';
    const titleColor = contentColor === 'text-white' ? 'text-white' : 'text-product-forest-100';

    return [color ? `bg-[${color}]` : 'bg-product-sand-200', contentColor, titleColor];
  }, [item]);

  return (
    <li
      onClick={handleAppointmentClick}
      className={`cursor-pointer mt-1 p-1 rounded-md ${appointmentTypeColor} grid grid-cols-2 gap-2`}
    >
      <div className='flex flex-col'>
        <p className={`${textTitleColor} font-semibold text-xs`}>{item.title}</p>
        <p className='font-light text-[11px]'>{formatDate}</p>
      </div>

      <div className={`leading-[12px] ${textContentColor} text-[9px] 0 flex justify-between`}>
        <div className='flex flex-col'>
          <p>Type: {item.type || 'N/A'}</p>
          <p>Primary provider: {item.providerName || 'N/A'}</p>
          <p className='max-w-xs text-ellipsis overflow-hidden ...'>
            Rooms: {item.rooms.map((room) => room.name).join(', ') || 'N/A'}
          </p>
        </div>

        <div className='flex mr-2'>
          {item.athenaAppointmentId && (
            <div>
              <img src={AthenaIcon} alt='Synced to Athena' width={20} height={20} />
            </div>
          )}

          {item.googleCalendarEventId && (
            <div>
              <img
                src={GCalIcon}
                alt='Synced to Google Calendar'
                aria-label='Synced to Google Calendar'
                width={20}
                height={20}
              />
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
