import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { DayPicker, getDefaultClassNames } from 'react-day-picker';
import 'react-day-picker/style.css';

import { Cmo, Personnel, Resources, SchedulerViewType } from '@/@types';
import { SelectFilterWrapper } from '@/components';
import { useToastContext } from '@/contexts';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import { OnWeeklyFiltersChange, WeeklyFiltersProps } from '../hooks';

import { SavedViewDropdown } from './SavedViewDropdown';

type WeeklyCalendarFiltersProps = {
  cmosFilterList: Cmo[];
  currentDate: Date;
  filters: WeeklyFiltersProps;
  onCurrentDateChange: (Date: Date) => void;
  onResourcesChange: (resources: Resources[]) => void;
  providersFilterList: Personnel[];
  setFilterItem: (key: keyof WeeklyFiltersProps, value: any[]) => void;
};

export const WeeklyCalendarFilters = ({
  cmosFilterList,
  currentDate,
  filters,
  onCurrentDateChange,
  onResourcesChange,
  providersFilterList,
  setFilterItem,
}: WeeklyCalendarFiltersProps) => {
  const { toast } = useToastContext();
  const { selectedView, savedViews, selectCurrentSchedulerView, saveViewForUser } =
    useSchedulerSavedViewContext();

  const [schedulerFilters, setSchedulerFilters] = useState<Resources[]>([]);
  const [isSaveSchedulerViewInProgress, setIsSaveSchedulerViewInProgress] = useState(false);

  const weeklySavedViews = useMemo(
    () => savedViews.filter((sv) => sv.type === SchedulerViewType.WEEK),
    [savedViews]
  );

  const isToEnableClearFilters = useMemo(
    () => !!selectedView || !!filters.cmos.length || !!filters.personnel.length,
    [filters.cmos.length, filters.personnel.length, selectedView]
  );

  const isToShowSaveViewButton = useMemo(
    () =>
      !!(
        (!selectedView || !savedViews?.length) &&
        (filters.cmos.length || filters.personnel.length)
      ),
    [selectedView, savedViews?.length, filters.cmos.length, filters.personnel.length]
  );

  const handleOnFiltersChange = useCallback(
    ({ key, value }: OnWeeklyFiltersChange) => {
      const previousFiltersIds = schedulerFilters.map(({ resourceId }) => resourceId);
      const newFilter = value.filter(({ resourceId }) => !previousFiltersIds.includes(resourceId));
      let newFilterList: Resources[] = [];
      if (newFilter.length) {
        newFilterList = [...schedulerFilters, ...newFilter];
      } else {
        const newIds = value.map(({ resourceId }) => resourceId);
        const removeFilterItem = filters[key]
          ?.filter(({ resourceId }) => !newIds.includes(resourceId))
          .map(({ resourceId }) => resourceId);
        newFilterList = schedulerFilters.filter(
          ({ resourceId }) => !removeFilterItem.includes(resourceId)
        );
      }
      onResourcesChange(newFilterList);
      setFilterItem(key, value);
    },
    [filters, onResourcesChange, schedulerFilters, setFilterItem]
  );

  const handleOnFiltersSort = useCallback(
    ({ key, value }: OnWeeklyFiltersChange) => {
      setFilterItem(key, value);
    },
    [setFilterItem]
  );

  const clearFilters = useCallback(() => {
    setFilterItem('personnel', []);
    setFilterItem('cmos', []);
    onResourcesChange([]);
  }, [setFilterItem, onResourcesChange]);

  const addSavedView = useCallback(async () => {
    setIsSaveSchedulerViewInProgress(true);
    try {
      const viewCreated = await saveViewForUser(
        {
          cmos: filters.cmos.map((cmo) => Number(cmo.resourceId.split('-')[1])),
          personnel: filters.personnel.map((personnel) =>
            Number(personnel.resourceId.split('-')[1])
          ),
        },
        SchedulerViewType.WEEK
      );
      if (viewCreated) {
        selectCurrentSchedulerView(viewCreated, true);
      }
    } catch (error) {
      toast?.current?.show({
        severity: 'error',
        summary: 'Failed to create a new saved view',
        detail: `Try again. If error persists, message the #product-support channel in Slack`,
        life: 8000,
      });
    } finally {
      setIsSaveSchedulerViewInProgress(false);
    }
  }, [saveViewForUser, filters.cmos, filters.personnel, selectCurrentSchedulerView, toast]);

  useEffect(() => {
    setSchedulerFilters([...filters.cmos, ...filters.personnel]);
  }, [filters]);

  return (
    <div className='px-1'>
      <CalendarFilter currentDate={currentDate} onCurrentDateChange={onCurrentDateChange} />

      <SavedViewDropdown
        selectedView={selectedView}
        selectCurrentSchedulerView={selectCurrentSchedulerView}
        savedViews={weeklySavedViews}
      />

      <SelectFilterWrapper
        title='Clinician'
        label='resourceTitle'
        options={providersFilterList}
        dataKey='resourceId'
        onChange={(value) => handleOnFiltersChange({ key: 'personnel', value })}
        placeholder='Select'
        value={providersFilterList?.length ? filters.personnel : null}
        sortable
        onSort={(value) => handleOnFiltersSort({ key: 'personnel', value })}
      />

      <SelectFilterWrapper
        title='Pod'
        label='resourceTitle'
        options={cmosFilterList.sort((a, b) => a.resourceTitle.localeCompare(b.resourceTitle))}
        onChange={(value) => handleOnFiltersChange({ key: 'cmos', value })}
        placeholder='Select'
        dataKey='resourceId'
        value={filters.cmos}
        sortable
        onSort={(value) => handleOnFiltersSort({ key: 'personnel', value })}
      />
      <Button
        disabled={isSaveSchedulerViewInProgress || !isToShowSaveViewButton}
        onClick={addSavedView}
        label='Save view'
        size='small'
        type='submit'
        className='mt-4 w-full'
      />

      <Button
        disabled={!isToEnableClearFilters}
        label='Clear filters'
        size='small'
        className='mt-4 w-full p-button p-button-outlined'
        onClick={clearFilters}
      />
    </div>
  );
};

type CalendarFilterProps = Pick<WeeklyCalendarFiltersProps, 'currentDate' | 'onCurrentDateChange'>;

const CalendarFilter = ({ currentDate, onCurrentDateChange }: CalendarFilterProps) => {
  const defaultClassNames = getDefaultClassNames();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:next-line
    <DayPicker
      classNames={{
        button_next: 'h-5 w-5',
        button_previous: 'h-5 w-5',
        caption: 'h-[32px]',
        caption_label: 'h-5 text-base font-medium tracking-tight text-fern',
        chevron: 'w-4 h-4 fill-moss',
        day: `${defaultClassNames.day} text-xs h-[39px] w-[39px] border-fern`,
        day_button: `${defaultClassNames.day_button} text-sm border-fern border-none rounded-[50%] h-[39px] w-[39px]`,
        nav: `${defaultClassNames.nav} h-5`,
        month_caption: 'h-5 text-base font-medium tracking-tight text-fern',
        month_grid: `${defaultClassNames.month_grid} mt-[10px]`,
        outside: 'opacity-30',
        selected: `${defaultClassNames.selected} text-xs font-normal bg-fern text-white rounded-[50%]`,
        today: ``,
        weekday: `${defaultClassNames.weekday} text-[rgba(255, 255, 255, 0.40)] text-xs font-medium`,
      }}
      fixedWeeks
      ISOWeek
      mode='single'
      onSelect={onCurrentDateChange}
      selected={currentDate}
      showOutsideDays
    />
  );
};
