import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { DateTime } from 'luxon';
import { Skeleton } from 'primereact/skeleton';
import { AppointmentFilters, AppointmentList } from '@/components';
import { useAppointments, useProviders, useRooms } from '@/hooks';
import {
  AppointmentFilterResourceItem,
  AppointmentFiltersChange,
  AppointmentsSearch,
  Atria,
} from '@/@types';
import { useAppointmentTypeContext, useToastContext } from '@/contexts';
import { SchedulerLayout } from '@/components/Layouts';
import { useVisitTypeTemplates } from '@/features/AppointmentActions';
import { ObjectArrayHelper } from '@/helpers';

const mappIds = (filters: AppointmentFilterResourceItem[]): string => {
  return filters!
    .map((m) => m.resourceId)
    ?.join(',')
    .replace(/\D/g, '');
};

const convertDateToDateTimeObject = (date: Date) => {
  return DateTime.local(date!.getFullYear(), date!.getMonth() + 1, date!.getDate());
};

const getRangeDate = (startDateItem: Date | undefined, endDateItem: Date | undefined) => {
  const initialDate = startDateItem
    ? convertDateToDateTimeObject(startDateItem).startOf('day').toUTC().toString()
    : DateTime.fromFormat('1/1/2021', 'd/m/yyyy').startOf('day').toUTC().toString();
  const lastDate = endDateItem
    ? convertDateToDateTimeObject(endDateItem).endOf('day').toUTC().toString()
    : DateTime.now().plus({ year: 2 }).startOf('day').toUTC().toString();

  return {
    rangeQueryStart: initialDate,
    rangeQueryEnd: lastDate,
  };
};

export function AppointmentsPage() {
  const [appointments, setAppointments] = useState<Atria.Appointment[]>([]);
  const { appointmentTypesOptionsList, getAppointmentTypes } = useAppointmentTypeContext();
  const { providersList, cmosFilterList: podsList, findAllProviders } = useProviders();
  const [skeletonAnimation, setSkeletonAnimation] = useState<'wave' | 'none' | undefined>('wave');
  const [skeletonHidden, setSkeletonHidden] = useState(true);
  const { toast } = useToastContext();
  const { findAllRooms } = useRooms();
  const { findAllVisitTypeTemplates } = useVisitTypeTemplates();

  const { searchAppointments } = useAppointments();

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        getAppointmentTypes(),
        findAllVisitTypeTemplates(),
      ]);
      loader.hide();
    } catch (error) {
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reload the page. If error persists, message the #product-support channel in Slack`,
        life: 60000,
      });
    }
  }, [findAllProviders, findAllRooms, findAllVisitTypeTemplates, getAppointmentTypes, toast]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  const listAppointments = useCallback(
    async (filters: AppointmentFiltersChange) => {
      const payload: AppointmentsSearch = {
        ...(filters.appointmentTypes?.length
          ? { appointmentTypeIds: mappIds(filters.appointmentTypes) }
          : {}),
        ...(filters.providers?.length || filters.cmos?.length
          ? {
              providersIds:
                (filters.providers ? mappIds(filters.providers) : '') +
                (filters.cmos ? mappIds(filters.cmos) : ''),
            }
          : {}),
        ...(filters.patientId ? { patientsIds: filters.patientId } : {}),
        ...getRangeDate(filters.startDate, filters.endDate),
      };

      const appointmentList = await searchAppointments(payload);
      setAppointments(appointmentList);
    },
    [searchAppointments]
  );

  const groupedAppointments = useMemo(() => {
    const appointmentsGrouped = ObjectArrayHelper.groupByMonthYearAndDayPatient(appointments || []);
    return Object.keys(appointmentsGrouped).map((monthYear) => ({
      monthYear: monthYear,
      patientVisits: Object.keys(appointmentsGrouped[monthYear]).map((dayPatient: string) => ({
        day: dayPatient.split('-')[0],
        patientId: dayPatient.split('-')[1],
        appointments: appointmentsGrouped[monthYear][dayPatient].sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        ),
      })),
    }));
  }, [appointments]);

  const numberOfPatientVisits = useMemo(
    () =>
      groupedAppointments.reduce((count, monthYear) => count + monthYear.patientVisits.length, 0),
    [groupedAppointments]
  );

  const onResourcesChange = useCallback(
    async (filters: AppointmentFiltersChange) => {
      try {
        setSkeletonAnimation('wave');
        setSkeletonHidden(false);
        await listAppointments(filters);
      } catch (error) {
        setAppointments([]);
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to get appointments',
          life: 3000,
        });
      } finally {
        setSkeletonHidden(true);
      }
    },
    [listAppointments, toast]
  );

  return (
    <SchedulerLayout isLocationRequired={false}>
      <div className='flex flex-col lg:flex-row'>
        <div className='min-w-[284px] lg:max-w-[284px] p-6 lg:ml-5'>
          <AppointmentFilters
            providersOptionsList={providersList.map((i) => ({
              resourceId: i.resourceId.replace('provider-', ''),
              resourceTitle: i.resourceTitle,
            }))}
            appointmentTypesOptionsList={appointmentTypesOptionsList.map((i) => ({
              resourceId: i.id.toString(),
              resourceTitle: i.name,
            }))}
            podOptionsList={podsList}
            onResourcesChange={onResourcesChange}
          />
        </div>
        <div className={'m-4 lg:w-2/4 lg:max-w-7xl lg:max-h-[calc(100vh-8rem)] bg-[white]'}>
          <div className='border-b-[1px] border-gray-1 p-[16px]'>
            <h1 className="font-['Scto Grotesk A'] flex justify-center text-kelp font-medium text-2xl">
              Scheduled
            </h1>
            <div className='flex justify-center text-[11px]'>
              {numberOfPatientVisits} visits • {appointments.length} appts
            </div>
          </div>

          {skeletonHidden ? (
            <AppointmentList appointmentList={groupedAppointments} classNames='mt-5' />
          ) : (
            <div className='m-4 w-full'>
              <Skeleton
                className='flex lg:max-w-4xl h-[350px]'
                animation={skeletonAnimation}
              ></Skeleton>
            </div>
          )}
        </div>
      </div>
    </SchedulerLayout>
  );
}
