import { Button } from 'primereact/button';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { Atria, Patient } from '@/@types';
import { patientsService } from '@/services';
import styles from './SearchPatientByName.module.css';
import { env } from '@/utils/constants';

type Props = {
  disabled?: boolean;
  defaultPatientId?: number | null;
  defaultFirstName?: string | null;
  defaultLastName?: string | null;
  defaultFirstNameUsed?: string | null;
  titleCss?: string;
  heightCss?: string;
  onSelect: (patients: Atria.FindAllPatients.Response[0] | null) => void;
};

type PatientSearchParams = {
  firstName?: string;
  lastName?: string;
  patientId?: number;
};

export const SearchPatientByNameOrId = ({
  disabled = false,
  defaultPatientId,
  defaultFirstName,
  defaultLastName,
  defaultFirstNameUsed,
  titleCss,
  heightCss,
  onSelect,
}: Props) => {
  const [patientOptions, setPatientOptions] = useState<Atria.FindAllPatients.Response>([]);
  const [patientSelected, setPatientSelected] = useState<number | null>(defaultPatientId || null);
  const [firstName, setFirstName] = useState<string>(defaultFirstName || '');
  const [lastName, setLastName] = useState<string>(defaultLastName || '');
  const [firstNameUsed, setFirstNameUsed] = useState<string | null>(defaultFirstNameUsed || null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const patientData = useMemo(
    () => patientOptions?.find((patient) => patient.id === patientSelected),
    [patientOptions, patientSelected]
  );

  const [patientId, setPatientId] = useState<number | null>(defaultPatientId || null);

  const handlePatientSearch = useCallback(async (params: PatientSearchParams) => {
    setIsLoading(true);
    const response = await patientsService.searchPatientByNameOrPatientId(params);
    setPatientOptions(response.data);
    setIsSubmitted(true);
    setIsLoading(false);
  }, []);

  const handlePatientRemove = () => {
    setPatientSelected(null);
    onSelect(null);
    setPatientOptions([]);
    setIsSubmitted(false);
    setFirstName('');
    setLastName('');
    setFirstNameUsed('');
    setPatientId(null);
  };

  const handlePatientDropdown = useCallback(
    (event: DropdownChangeEvent) => {
      const pId = event.target.value;
      setPatientSelected(pId);
      const selectedPatientTemp = patientOptions.find((p) => p.id === pId);
      if (selectedPatientTemp) {
        onSelect(selectedPatientTemp);
      }
    },
    [onSelect, patientOptions]
  );

  const noResultsFound = useMemo(() => patientOptions.length === 0, [patientOptions]);
  const isButtonDisabled = useMemo(
    () => (!firstName && !lastName && !patientId) || isLoading,
    [firstName, isLoading, lastName, patientId]
  );

  const patientListTemplate = (option: Patient) => {
    return (
      <div className='flex align-items-center'>
        <div>
          {option.id} -{' '}
          {`${option.firstName}${option.firstNameUsed ? ` "${option.firstNameUsed}" ` : ' '}${option.lastName}`}
        </div>
      </div>
    );
  };

  const handleOnEnter = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return;

      e.preventDefault();
      handlePatientSearch({ firstName, lastName, patientId: patientId || undefined });
    },
    [firstName, handlePatientSearch, lastName, patientId]
  );

  return (
    <div
      className={classNames(styles.container, {
        [styles.columnDisabled]: disabled,
      })}
    >
      <label
        htmlFor='lastName'
        className={classNames(titleCss || 'text-kelp font-bold text-xs mb-2', {
          'text-rust': isSubmitted && noResultsFound,
        })}
      >
        Patient
      </label>
      {!patientSelected && (
        <div className='grid grid-cols-2 gap-2'>
          {env.APP_FEATURE_FLAGS.IS_TO_SHOW_SEARCH_BY_MRN && (
            <InputText
              disabled={disabled}
              id='patientId'
              name='patientId'
              placeholder='MRN'
              style={{
                flex: '1',
              }}
              onKeyDown={handleOnEnter}
              className={classNames('col-span-1 !flex-1', heightCss, {
                'border-rust': isSubmitted && noResultsFound,
              })}
              onChange={(e) => setPatientId(Number(e.target.value))}
              readOnly={isLoading}
              keyfilter='pint'
            />
          )}
          <InputText
            disabled={disabled}
            id='firstName'
            name='firstName'
            placeholder='First name'
            onKeyDown={handleOnEnter}
            className={classNames('col-span-1 min-w-[100px]  h-[30px] !flex-1', heightCss, {
              'border-rust': isSubmitted && noResultsFound,
            })}
            onChange={(e) => setFirstName(e.target.value)}
            readOnly={isLoading}
          />
          <InputText
            disabled={disabled}
            id='lastName'
            name='lastName'
            placeholder='Last name'
            onKeyDown={handleOnEnter}
            className={classNames('col-span-1 h-[30px] min-w-[100px] !flex-1', heightCss, {
              'border-rust': isSubmitted && noResultsFound,
            })}
            onChange={(e) => setLastName(e.target.value)}
            readOnly={isLoading}
          />
          <Button
            type='button'
            icon='pi pi-search'
            size='small'
            onClick={() =>
              handlePatientSearch({ firstName, lastName, patientId: patientId || undefined })
            }
            className={classNames('flex w-full col-span-1 h-[30px] min-w-fit !flex-1', heightCss)}
            disabled={isButtonDisabled}
            loading={isLoading}
          />
        </div>
      )}
      {patientSelected && (
        <div className='w-full'>
          <span className='w-full p-input-icon-right'>
            {!disabled && (
              <i className='pi pi-times cursor-pointer' onClick={handlePatientRemove} />
            )}
            <InputText
              readOnly
              className={classNames('w-full flex flex-col h-[30px]', heightCss)}
              disabled={disabled}
              value={`${patientData?.firstName || firstName}${patientData?.firstNameUsed || firstNameUsed ? ` "${patientData?.firstNameUsed || firstNameUsed}" ` : ' '}${patientData?.lastName || lastName}`}
            />
          </span>
        </div>
      )}
      {!patientSelected && patientOptions.length > 0 && (
        <Dropdown
          className={
            (styles.dropdown, `${heightCss || ''}   mt-2 flex justify-center items-center`)
          }
          options={patientOptions}
          value={patientSelected}
          onChange={handlePatientDropdown}
          placeholder='Select a patient'
          optionValue='id'
          filter
          itemTemplate={patientListTemplate}
        />
      )}

      {!patientSelected && !patientOptions.length && isSubmitted && (
        <small className={styles.error}>No results found</small>
      )}
    </div>
  );
};
