import { useCallback, useState } from 'react';
import { AppointmentFilterResourceItem, AppointmentFiltersChange, Atria } from '@/@types';
import { SelectFilterWrapper } from '../SelectFilterWrapper';
import { SearchPatientByNameOrId } from '@/components';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Nullable } from 'primereact/ts-helpers';
import './CustomCalendar.css';

export type AppointmentFiltersProps = {
  providersOptionsList: AppointmentFilterResourceItem[];
  appointmentTypesOptionsList: AppointmentFilterResourceItem[];
  podOptionsList: AppointmentFilterResourceItem[];
  onResourcesChange: (filters: AppointmentFiltersChange) => void;
};

export const AppointmentFilters = ({
  providersOptionsList,
  appointmentTypesOptionsList,
  // /# TODO podOptionsList,
  onResourcesChange,
}: AppointmentFiltersProps) => {
  const [appointmentFilters, setAppointmentFilters] = useState<AppointmentFiltersChange>({});

  const handlePatientOnChange = useCallback(
    async (patient: Atria.FindAllPatients.Response[0] | null) => {
      setAppointmentFilters({ ...appointmentFilters, patientId: patient?.id.toString() });
    },
    [setAppointmentFilters, appointmentFilters]
  );

  const handleChangeDate = useCallback(
    (field: 'startDate' | 'endDate', value?: Nullable<string | Date | Date[]>) => {
      setAppointmentFilters({
        ...appointmentFilters,
        [field]: value as Date,
      });
    },
    [appointmentFilters]
  );

  const submit = useCallback(() => {
    onResourcesChange(appointmentFilters);
  }, [appointmentFilters, onResourcesChange]);
  const isSearchEnabled = !!(
    appointmentFilters.startDate ||
    appointmentFilters.endDate ||
    appointmentFilters.patientId ||
    appointmentFilters.providers?.length ||
    appointmentFilters.appointmentTypes?.length ||
    appointmentFilters.cmos?.length
  );

  return (
    <>
      <h3 className='font-sans text-kelp font-medium text-2xl mt-2'>Filters</h3>
      <div className='flex mt-[36px]'>
        <SearchPatientByNameOrId
          titleCss='font-sans font-medium text-sm text-product-grey-400 py-1 block'
          heightCss='h-[48px] rounded-full !px-[24px]'
          onSelect={(value) => handlePatientOnChange(value)}
        />
      </div>
      <div className='mt-8'>
        <SelectFilterWrapper
          title='Clinician'
          label='resourceTitle'
          options={providersOptionsList}
          dataKey='resourceId'
          onChange={(providers) => setAppointmentFilters({ ...appointmentFilters, providers })}
          placeholder='Select'
          value={appointmentFilters.providers || []}
          className='font-sans font-medium !text-sm text-product-grey-400 pb-3 block'
        />
      </div>
      {/* # TODO
      <div className='mt-6'>
        <SelectFilterWrapper
          title='Pod'
          label='resourceTitle'
          options={podOptionsList}
          dataKey='resourceId'
          onChange={(cmos) => setAppointmentFilters({ ...appointmentFilters, cmos })}
          placeholder='Select'
          value={appointmentFilters.cmos || []}
          className='font-sans font-medium !text-sm text-product-grey-400 pb-3 block'
        />
      </div> */}
      <div className='mt-6'>
        <SelectFilterWrapper
          title='Appointment Type'
          label='resourceTitle'
          options={appointmentTypesOptionsList}
          dataKey='resourceId'
          onChange={(appointmentTypes) =>
            setAppointmentFilters({ ...appointmentFilters, appointmentTypes })
          }
          placeholder='Select'
          value={appointmentFilters.appointmentTypes || []}
          className='font-sans font-medium text-xs text-product-grey-400 pb-3 block'
        />
      </div>
      <div className='mt-6 flex-1'>
        <label className='font-sans font-medium text-xs text-product-grey-400 py-1 block mb-2'>
          Start Date
        </label>
        <Calendar
          id='startDate'
          placeholder='Start Date'
          dateFormat='mm/dd/yy'
          locale='en'
          onChange={(value) => handleChangeDate('startDate', value.target.value)}
          value={appointmentFilters.startDate}
          className='h-[48px] w-full rounded-full text-sm'
        />
      </div>
      <div className='mt-8 flex-1'>
        <label className='font-sans font-medium text-xs text-product-grey-400 py-1 block mb-2'>
          End Date
        </label>
        <Calendar
          id='endDate'
          placeholder='End Date'
          dateFormat='mm/dd/yy'
          locale='en'
          onChange={(value) => handleChangeDate('endDate', value.target.value)}
          value={appointmentFilters.endDate}
          className='h-[48px] w-full rounded-full text-sm'
        />
      </div>
      <div className='mt-8'>
        <Button
          type='button'
          icon='pi pi-search'
          label='Search'
          size='small'
          onClick={submit}
          disabled={!isSearchEnabled}
          className='w-full h-[53px] py-[20px] px-[36px] rounded-full'
        />
      </div>
      <div
        className='mt-8 flex items-center text-sm text-kelp hover:cursor-pointer'
        onClick={() => setAppointmentFilters({})}
      >
        <i className='material-icons-outlined items-center mr-2 w-2xs text-base'>cancel</i> Clear
        filters
      </div>
    </>
  );
};
