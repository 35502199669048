import { useCallback, useEffect, useMemo, useState } from 'react';
import { loader } from 'react-global-loader';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import MobileDetect from 'mobile-detect';
import { Sidebar } from 'primereact/sidebar';

import { useAppointments, useGoogleCalendar, useProviders, useRooms } from '@/hooks';
import {
  AppointmentParsed,
  AppointmentWithResourcesList,
  Atria,
  Origin,
  PatientVisit,
  Resources,
} from '@/@types';
import { useAppointmentTypeContext, useToastContext } from '@/contexts';
import { OrderColumnsHelper, SchedulerHelper } from '@/helpers';
import { SchedulerLayout } from '@/components/Layouts';

import {
  WeeklyCalendar,
  CalendarSettingsDrawer,
  WeeklyCalendarFilters,
} from '@/features/Calendar/components';
import { parseAppointments } from '@/features/Calendar/utils';
import { useSchedulerSavedViewContext } from '@/features/Calendar/contexts/schedulerSavedViewContext';
import {
  useWeeklyCalendarFilters,
  useWeeklyCalendarSettings,
  useSchedulerSocket,
} from '@/features/Calendar/hooks';

import { useAppointmentActions } from '@/features/AppointmentActions/contexts';
import { useVisitTypeTemplates } from '@/features/AppointmentActions';

export function SchedulerWeeklyPage() {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();

  const { getAppointmentsByRange } = useAppointments();
  const { onAppointmentDetailsClick, onAppointmentCreateClick, onBackgroundAppointmentClick } =
    useAppointmentActions();
  const { appointmentTypesOptionsList, getAppointmentTypes } = useAppointmentTypeContext();
  const { googleCalendarAppointments, eventsOOOAndAllDay, findProvidersEventsOnGoogleCalendar } =
    useGoogleCalendar();
  const { visitTypeTemplates, findAllVisitTypeTemplates } = useVisitTypeTemplates();

  const {
    cmosFilterList,
    providers: providersOptionsList,
    providersList,
    findAllProviders,
  } = useProviders();
  const { rooms: roomsOptionsList, suiteRoomsIds, findAllRooms } = useRooms();
  const { findAllViewsForUser } = useSchedulerSavedViewContext();
  const { toast } = useToastContext();
  const { filters, isViewingDefaultView, setFilterItem } = useWeeklyCalendarFilters();
  const viewSettings = useWeeklyCalendarSettings();

  const [appointments, setAppointments] = useState<Atria.Appointment[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [resources, setResources] = useState<Resources[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showCalendarSettings, setShowCalendarSettings] = useState(false);

  useSchedulerSocket(setAppointments, currentDate);

  const googleCalendarAppointmentsWithoutAllDayEvents = useMemo(
    () => googleCalendarAppointments.filter((event) => !event.isAllDay),
    [googleCalendarAppointments]
  );

  const googleAppointmentsByProviderId = useMemo(
    () =>
      SchedulerHelper.getGoogleAppointmentsByProviderId(
        googleCalendarAppointmentsWithoutAllDayEvents
      ),
    [googleCalendarAppointmentsWithoutAllDayEvents]
  );

  const calendarAppointments = useMemo(() => {
    let result = parseAppointments(appointments);

    if (!viewSettings.showAthenaAppointments) {
      result = result.filter((i: any) => i?.origin !== Origin.Athena);
    }

    if (!viewSettings.showUnconfirmedAppointments) {
      result = result.filter(
        (i: AppointmentWithResourcesList) => i.confirmed && i.origin === Origin.Atria
      );
    }

    if (viewSettings.showGoogleCalendarEvents) {
      result = [...result, ...(googleCalendarAppointments as any)];
    }

    return result;
  }, [
    appointments,
    googleCalendarAppointments,
    viewSettings.showAthenaAppointments,
    viewSettings.showGoogleCalendarEvents,
    viewSettings.showUnconfirmedAppointments,
  ]);

  const appointmentsToBackgroundEvents = useMemo(() => {
    if (!viewSettings.showAthenaAppointments) {
      return appointments.filter((apt) => apt.atriaAppointment);
    }

    return appointments;
  }, [appointments, viewSettings.showAthenaAppointments]);

  const appointmentsGroupByPatientId = useMemo(() => {
    return SchedulerHelper.groupAppointmentsByPatientId(appointmentsToBackgroundEvents);
  }, [appointmentsToBackgroundEvents]);

  const memoPatientVisits = useMemo(() => {
    const patientVisitsForAllResources = SchedulerHelper.getAppointmentsPerPatientId(
      appointmentsGroupByPatientId
    );

    const patientVisitsList: PatientVisit[] = [];

    patientVisitsList.push(
      ...SchedulerHelper.createBackgroundEventsForPatientWeeklyAppointments({
        patientVisitsForAllResources,
        resources,
        personnel: providersList,
        hidePatientName: false,
      })
    );
    // SHOW GOOGLE CALENDAR EVENTS
    if (
      viewSettings.showGoogleCalendarEvents &&
      SchedulerHelper.checkIfResourcesContains(resources, 'provider')
    ) {
      patientVisitsList.push(
        ...SchedulerHelper.createBackgroundEventsForGoogleAppointmentsWithMultipleDates(
          googleAppointmentsByProviderId
        )
      );
    }

    return patientVisitsList;
  }, [
    appointmentsGroupByPatientId,
    googleAppointmentsByProviderId,
    providersList,
    resources,
    viewSettings.showGoogleCalendarEvents,
  ]);

  const findWeekAppointments = useCallback(
    async (date: Date) => {
      const startDate = DateTime.fromJSDate(date).startOf('week');
      const endDate = DateTime.fromJSDate(date).endOf('week');

      loader.show();

      if (filters.stateInitialized) {
        findProvidersEventsOnGoogleCalendar(
          startDate.toFormat('MM-dd-yyyy'),
          filters.personnel,
          endDate.toFormat('MM-dd-yyyy')
        );
      }

      try {
        const weekAppointments = await getAppointmentsByRange({
          startDate: startDate.toUTC().toString(),
          endDate: endDate.toUTC().toString(),
        });

        setAppointments(weekAppointments);
      } catch (error) {
        toast?.current?.show({
          className: 'text-xl opacity-100',
          severity: 'error',
          summary: 'Failed to load appointments',
          detail: `Reload the page. If error persists, message the #product-support channel in Slack`,
          life: 60000,
        });
      } finally {
        loader.hide();
      }
    },
    [
      filters.personnel,
      filters.stateInitialized,
      findProvidersEventsOnGoogleCalendar,
      getAppointmentsByRange,
      toast,
    ]
  );

  const handleAppointmentClick = useCallback(
    (event: AppointmentParsed) => {
      onAppointmentDetailsClick(event);
    },
    [onAppointmentDetailsClick]
  );

  const handleBackgroundAppointmentClick = useCallback(
    (event: PatientVisit) => {
      onBackgroundAppointmentClick(event);
    },
    [onBackgroundAppointmentClick]
  );

  const handleAppointmentCreateClick = useCallback(() => {
    onAppointmentCreateClick({
      start: currentDate,
    });
  }, [currentDate, onAppointmentCreateClick]);

  const handleOnResourcesChange = useCallback((values: Resources[]) => {
    return setResources(OrderColumnsHelper.order(values));
  }, []);

  const initialLoad = useCallback(async () => {
    loader.show();
    try {
      await Promise.all([
        findAllRooms(),
        findAllProviders(),
        getAppointmentTypes(),
        findAllViewsForUser(),
        findAllVisitTypeTemplates(),
      ]);
      loader.hide();
    } catch (error) {
      toast?.current?.show({
        className: 'text-xl opacity-100',
        severity: 'error',
        summary: 'Failed to load important data',
        detail: `Reload the page. If error persists, message the #product-support channel in Slack`,
        life: 60000,
      });
    }
  }, [
    findAllProviders,
    findAllRooms,
    findAllViewsForUser,
    findAllVisitTypeTemplates,
    getAppointmentTypes,
    toast,
  ]);

  useEffect(() => {
    findWeekAppointments(currentDate);
  }, [currentDate, findWeekAppointments]);

  useEffect(() => {
    handleOnResourcesChange([...filters.personnel, ...filters.cmos]);
  }, [filters.personnel, filters.cmos, handleOnResourcesChange]);

  useEffect(() => {
    initialLoad();
  }, [initialLoad]);

  return (
    <SchedulerLayout
      currentDate={DateTime.fromJSDate(currentDate).toFormat('MM-dd-yyyy')}
      setShowFilters={setShowFilters}
      setShowCalendarSettings={setShowCalendarSettings}
    >
      <div className='h-full p-4'>
        <motion.aside
          variants={{
            open: { x: 0 },
            closed: { x: -300 },
          }}
          transition={{ damping: 40 }}
          animate={showFilters ? 'open' : 'closed'}
          className='h-full absolute w-screen max-w-[280px] flex flex-col'
        >
          {isMobile ? (
            <Sidebar visible={showFilters} onHide={() => setShowFilters(false)} position='left'>
              <WeeklyCalendarFilters
                cmosFilterList={cmosFilterList}
                currentDate={currentDate}
                filters={filters}
                onCurrentDateChange={(date: Date) => setCurrentDate(date)}
                onResourcesChange={handleOnResourcesChange}
                providersFilterList={providersList}
                setFilterItem={setFilterItem}
              />
            </Sidebar>
          ) : (
            <WeeklyCalendarFilters
              cmosFilterList={cmosFilterList}
              currentDate={currentDate}
              filters={filters}
              onCurrentDateChange={(date: Date) => setCurrentDate(date)}
              onResourcesChange={handleOnResourcesChange}
              providersFilterList={providersList}
              setFilterItem={setFilterItem}
            />
          )}
        </motion.aside>

        <motion.main
          initial={{ marginLeft: isMobile ? 0 : '288px' }}
          variants={{
            margin: { marginLeft: isMobile ? 0 : '288px' },
            noMargin: { marginLeft: '0px' },
          }}
          transition={{ damping: isMobile ? 0 : 40 }}
          animate={showFilters ? 'margin' : 'noMargin'}
          className='h-full'
        >
          <WeeklyCalendar
            appointmentTypesList={appointmentTypesOptionsList}
            backgroundEvents={viewSettings.showPatientBackgroundEvents ? memoPatientVisits : []}
            currentDate={currentDate}
            events={isViewingDefaultView ? [] : calendarAppointments}
            providersOptionsList={providersOptionsList}
            onAppointmentClick={handleAppointmentClick}
            onBackgroundAppointmentClick={handleBackgroundAppointmentClick}
            showPatientBackgroundEvents={viewSettings.showPatientBackgroundEvents}
            onCalendarViewSettingsClick={() => setShowCalendarSettings((prev) => !prev)}
            onCurrentDateChange={setCurrentDate}
            onNewAppointmentClick={handleAppointmentCreateClick}
            onToggleFiltersClick={() => setShowFilters((prev) => !prev)}
            resources={resources}
            roomsOptionsList={roomsOptionsList}
            showFullCalendar={viewSettings.showFullCalendar}
            suiteRoomsIds={suiteRoomsIds}
            visitTypeTemplates={visitTypeTemplates}
            eventsOOOAndAllDay={eventsOOOAndAllDay}
            showWeekends={viewSettings.showWeekends}
          />

          <CalendarSettingsDrawer
            isVisible={showCalendarSettings}
            onHide={() => setShowCalendarSettings(false)}
            {...viewSettings}
          />
        </motion.main>
      </div>
    </SchedulerLayout>
  );
}
