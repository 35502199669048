import { AppointmentWithDate, Atria, ConfirmedTypes, Origin } from '@/@types';
import { googleService } from '@/services';
import { env } from './constants';

const homeAthenaDepartment = [
  Atria.AthenaDepartment.NY_HOME_SERVICES,
  Atria.AthenaDepartment.PB_HOME_SERVICES,
];
const otherAthenaDepartment = [
  Atria.AthenaDepartment.NY_INSTITUTE,
  Atria.AthenaDepartment.PB_INSTITUTE,
  Atria.AthenaDepartment.NY_PEDIATRICS,
  Atria.AthenaDepartment.PB_PEDIATRICS,
];
const nyAthenaDepartment = [
  Atria.AthenaDepartment.NY_INSTITUTE,
  Atria.AthenaDepartment.NY_HOME_SERVICES,
  Atria.AthenaDepartment.NY_PEDIATRICS,
];
const enum HomeVisitRooms {
  NY = 16,
  PB = 31,
}

export const confirmedTypesOptions = [
  { name: 'Hold', value: ConfirmedTypes.HOLD_CONFIRMED },
  { name: 'Unconfirmed', value: ConfirmedTypes.UNCONFIRMED },
  { name: 'Confirmed', value: ConfirmedTypes.FULLY_CONFIRMED },
  { name: 'Cancelled', value: ConfirmedTypes.CANCELLED },
];

export const parseAppointments = (
  appointments: Atria.Appointment[],
  suiteRooms?: number[]
): AppointmentWithDate[] => {
  const results: AppointmentWithDate[] = [];
  for (const appointment of appointments) {
    const item = mountAppointmentObject(appointment);
    results.push(item);

    const athenaDepartmentId = appointment.athenaDepartmentId as Atria.AthenaDepartment;
    const isHomeAthenaDepartment = homeAthenaDepartment.includes(athenaDepartmentId);
    const isHomeVisitTypeInOtherDepartament =
      otherAthenaDepartment.includes(athenaDepartmentId) &&
      appointment.type?.toLowerCase().includes('home');
    if (appointment.rooms?.length || isHomeAthenaDepartment || isHomeVisitTypeInOtherDepartament) {
      const homeVisitRoomId = nyAthenaDepartment.includes(athenaDepartmentId)
        ? HomeVisitRooms.NY
        : HomeVisitRooms.PB;
      const isHomeVisitNotMappedYet = !appointment.rooms.find(
        (room) => room.id === homeVisitRoomId
      );
      const isToAddAppointmentToHomeVisitRoom =
        !appointment.atriaAppointment &&
        isHomeVisitNotMappedYet &&
        (isHomeAthenaDepartment || isHomeVisitTypeInOtherDepartament);

      const rooms = isToAddAppointmentToHomeVisitRoom
        ? [...appointment.rooms, { id: homeVisitRoomId }]
        : appointment.rooms;
      for (const { id } of rooms) {
        results.push({
          ...item,
          resourceId: `room-${id}`,
          hasPatientVisit: !!(
            suiteRooms &&
            suiteRooms.includes(id) &&
            appointments.find(
              (appt) =>
                appt.patientId &&
                appointment.patientId &&
                appt.patientId === appointment.patientId &&
                appt.rooms.length > 0
            )
          ),
        });
      }
    }

    if (appointment.providers.length) {
      for (const { id, type } of appointment.providers) {
        if (type === 'EQUIPMENT') {
          results.push({
            ...item,
            resourceId: `equipment-${id}`,
          });
        } else {
          results.push({
            ...item,
            resourceId: `provider-${id}`,
          });
        }
      }
    }

    if (appointment.patientPrimaryProviderId) {
      results.push({
        ...item,
        resourceId: `cmo-${appointment.patientPrimaryProviderId}`,
      });
    }

    if (appointment.patientId) {
      results.push({ ...item, resourceId: `patient-${appointment.patientId}` });
    }
  }
  return results;
};

export const mountAppointmentObject = (
  appointment: Atria.Appointment,
  isValidProviderAppointment = true
): AppointmentWithDate => {
  return {
    ...appointment,
    ...(isValidProviderAppointment && {
      resourceId: `provider-${appointment.providerId!}`,
    }),
    appointmentId: appointment.id,
    date: new Date(appointment.date),
    end: new Date(appointment.end),
    updatedAt: new Date(appointment.updatedAt),
    provider: {
      id: appointment.providerId!,
      label: appointment.providerName!,
    },
    providers: appointment.providers.map(({ id, name, type }) => ({ id, name, type })),
    rooms: appointment.rooms.map(({ id, name: label }) => ({ id, label })),
    origin: appointment.atriaAppointment ? Origin.Atria : Origin.Athena,
  };
};

/**
 * Check if appointment of type telehealth
 */
export function isTelehealth(appointmentType: Atria.Appointment['type'] | undefined) {
  if (appointmentType) {
    return appointmentType.toLowerCase().includes('tele');
  }
  return false;
}

/**
 * Check if appointment of type home visit
 */
export function isAddressRequired(appointmentType: string | undefined) {
  return isHomeType(appointmentType) || isExternalType(appointmentType);
}

export function isHomeType(appointmentType: string | undefined) {
  return !!appointmentType?.toLowerCase()?.includes('home');
}

export function isExternalType(appointmentType: string | undefined) {
  return !!appointmentType?.toLowerCase()?.includes('external');
}

type ValidAthenaAppointment = Pick<Atria.Appointment, 'patientId' | 'providerId' | 'type' | 'date'>;

/**
 * Check if appointment meets athena sync requirements
 */
export function checkIfEnableSchedulerAthenaSync(
  data: ValidAthenaAppointment & { status: ConfirmedTypes }
) {
  return !!(
    data.type &&
    data.status !== ConfirmedTypes.HOLD_CONFIRMED &&
    data.providerId &&
    data.patientId &&
    data.date &&
    env.APP_FEATURE_FLAGS.IS_TO_SHOW_ATHENA_APPOINTMENT_SYNC
  );
}

/**
 * Requests the address of the appointment and formats it to be created/updated
 */
export async function getAppointmentAddress(placeId: string) {
  try {
    if (!placeId) return {};

    const { data: placeDetails } = await googleService.getAddressDetails(placeId);

    const typesMap =
      placeDetails?.addressComponents.reduce((acc: Record<string, string>, component) => {
        component.types.forEach((type: string) => {
          acc[type] = component.longText;
        });

        return acc;
      }, {}) ?? {};

    return {
      address: typesMap['route'] ?? '',
      addressNumber: typesMap['street_number'] ?? '',
      city: typesMap['locality'] ?? '',
      state: typesMap['administrative_area_level_1'] ?? '',
      country: typesMap['country'] ?? '',
      zipCode: typesMap['postal_code'] ?? '',
      latitude: placeDetails?.location?.latitude?.toString() ?? '',
      longitude: placeDetails?.location?.longitude?.toString() ?? '',
      addressDetails:
        [typesMap['neighborhood'], typesMap['sublocality'], typesMap['administrative_area_level_2']]
          .filter((detail) => !!detail)
          .join(', ') ?? '',
    };
  } catch {
    return {};
  }
}
