import { PropsWithChildren, useMemo } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

import { env } from '@/utils/constants';
import { Permission, useAuthContext, useLocation } from '@/contexts';
import { getPatientAthenaChartUrl } from '@/features/AppointmentActions/utils';
import {
  AppointmentWithDate,
  AppointmentWithResourcesList,
  ConfirmedTypes,
  ConflictSource,
  ConflictSourceLabel,
} from '@/@types';

type DetailsContentProps = {
  appointment: AppointmentWithResourcesList | AppointmentWithDate;
  onShowConferenceLink: () => void;
};

export const DetailsContent = ({ appointment, onShowConferenceLink }: DetailsContentProps) => {
  const { hasPermission } = useAuthContext();
  const { getAthenaDepartmentNameById } = useLocation();

  const { startDate, endDate } = useMemo(() => {
    return {
      startDate: appointment && DateTime.fromJSDate(appointment.date).toFormat('EEE, MMMM dd - t'),
      endDate: appointment && DateTime.fromJSDate(appointment?.end || new Date()).toFormat('t'),
    };
  }, [appointment]);

  return (
    <div>
      <AppointmentDateRow date={`${startDate} - ${endDate}`} />

      <AppointmentCheckInOutRow
        checkIn={appointment.checkIn}
        checkOut={appointment.checkOut}
        conferenceLink={
          env.APP_FEATURE_FLAGS.IS_TO_ENABLE_TELEHEALTH_LINK && appointment.conferenceLink
        }
        isConferenceLinkDisabled={!hasPermission(Permission.EDIT_APPOINTMENT)}
        onConfirmConferenceLinkDelete={onShowConferenceLink}
      />

      <DefaultRow icon='mode_comment' label='Description' content={appointment.description} />

      <AppointmentPatientRow
        athenaChartUrl={getPatientAthenaChartUrl(appointment.patientId)}
        patientFirstName={appointment.firstName}
        patientFirstNameUsed={appointment.firstNameUsed}
        patientId={appointment.patientId}
        patientLastName={appointment.lastName}
      />

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_ADDRESS_APPOINTMENT && (
        <AppointmentAddress
          address={appointment.address}
          addressNumber={appointment.addressNumber}
          city={appointment.city}
          country={appointment.country}
          latitude={appointment.latitude}
          longitude={appointment.longitude}
          state={appointment.state}
          zipCode={appointment.zipCode}
        />
      )}

      <DefaultRow icon='badge' label='Provider' content={appointment.provider?.label} />

      <DefaultRow
        icon='person_pin_circle'
        label='Liaison'
        content={appointment?.providers
          ?.filter((p: any) => p.type === 'LIAISON')
          .map((p: any) => p.name)
          .join(', ')}
      />

      <DefaultRow
        icon='group'
        label='Additional Providers'
        content={appointment?.providers
          ?.filter((p: any) => p.type === 'ADDITIONAL')
          .map((p: any) => p.name)
          .join(', ')}
      />

      <DefaultRow
        icon='build'
        label='Resources'
        content={appointment?.providers
          ?.filter((p: any) => p.type === 'EQUIPMENT')
          .map((p: any) => p.name)
          .join(', ')}
      />

      <DefaultRow
        icon='apartment'
        label='Rooms'
        content={appointment.rooms?.map((r: any) => r.label).join(', ')}
      />

      {appointment.athenaDepartmentId && (
        <DefaultRow
          content={getAthenaDepartmentNameById(appointment.athenaDepartmentId)}
          icon='room_preferences'
          label='Athena department'
        />
      )}

      <DefaultRow content={appointment.type || ''} icon='label' label='Appointment type' />

      <DefaultRow
        content={
          (appointment.confirmed === ConfirmedTypes.FULLY_CONFIRMED
            ? 'Confirmed'
            : appointment.confirmed === ConfirmedTypes.HOLD_CONFIRMED
              ? 'Hold'
              : appointment.confirmed === ConfirmedTypes.CANCELLED
                ? 'Cancelled'
                : 'Unconfirmed') +
          ((appointment.athenaAppointmentId && ' - Athena appointment created') || '')
        }
        icon={
          [ConfirmedTypes.FULLY_CONFIRMED, ConfirmedTypes.HOLD_CONFIRMED].includes(
            appointment.confirmed as ConfirmedTypes
          )
            ? 'check'
            : 'close'
        }
        contentClass={appointment.confirmed === ConfirmedTypes.CANCELLED ? 'text-red-600' : ''}
        label={'Status'}
      />

      {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_APPOINTMENT_CONFLICT_SOURCE &&
        appointment.hasConflict && (
          <DefaultRow
            contentClass='text-red-600'
            content={appointment.conflicts
              ?.map(
                (conflict) =>
                  `${conflict.resourceName} (${ConflictSourceLabel[conflict.source as ConflictSource]})`
              )
              ?.join(', ')}
            label='Conflict'
            icon='report'
          />
        )}
    </div>
  );
};

type AppointmentDateRowProps = {
  date: string;
};

const AppointmentDateRow = ({ date }: AppointmentDateRowProps) => (
  <DetailsRow>
    <i className='material-icons-outlined mr-2'>calendar_month</i>
    <p>{date}</p>
  </DetailsRow>
);

type AppointmentCheckInOutRowProps = {
  checkIn?: string | undefined | null;
  checkOut?: string | undefined | null;
  conferenceLink?: string | null | false;
  isConferenceLinkDisabled?: boolean;
  onConfirmConferenceLinkDelete?: () => void;
};

const AppointmentCheckInOutRow = ({
  checkIn,
  checkOut,
  conferenceLink,
  isConferenceLinkDisabled,
  onConfirmConferenceLinkDelete,
}: AppointmentCheckInOutRowProps) => (
  <DetailsRow className='flex-col items-start'>
    <div className='flex w-full'>
      <div className='flex flex-col items-start w-full'>
        <div className='flex items-center'>
          <i className='material-icons-outlined mr-2'>schedule</i>
          <p>
            <b>Check-in:</b> {checkIn || '-'}
          </p>
        </div>
      </div>

      <div className='flex items-center w-full'>
        <i className='material-icons-outlined mr-2'>schedule</i>
        <b>Check-out:</b> {checkOut || '-'}
      </div>
    </div>

    {conferenceLink && (
      <div className='mt-6 w-full rounded-[10px]'>
        <Button
          label='Join with Zoom'
          size='small'
          className='w-full bg-[#2d8cff] text-white'
          outlined
          onClick={() => {
            window.open(conferenceLink, '_blank');
          }}
        >
          <i className='material-icons-outlined ml-1 h-6 w-6 text-base'>videocam</i>
        </Button>

        <div className='w-full flex mt-2 gap-1 justify-stretch'>
          <Button
            label='Copy to clipboard'
            iconPos='right'
            size='small'
            className='w-full'
            outlined
            onClick={() => navigator.clipboard.writeText(conferenceLink ?? '')}
          >
            <i className='material-icons-outlined ml-1 h-6 w-6 text-base'>content_copy</i>
          </Button>

          <Button
            label='Delete link'
            iconPos='right'
            size='small'
            className='w-full'
            outlined
            onClick={onConfirmConferenceLinkDelete}
            disabled={isConferenceLinkDisabled}
          >
            <i className='material-icons-outlined ml-1 h-6 w-6 text-base'>delete</i>
          </Button>
        </div>
      </div>
    )}
  </DetailsRow>
);

type AppointmentPatientRowProps = {
  athenaChartUrl?: string | null | undefined;
  patientFirstName?: string | null | undefined;
  patientFirstNameUsed?: string | null | undefined;
  patientLastName?: string | null | undefined;
  patientId?: number | null | undefined;
};

const AppointmentPatientRow = ({
  athenaChartUrl,
  patientFirstName,
  patientFirstNameUsed,
  patientId,
  patientLastName,
}: AppointmentPatientRowProps) => (
  <DetailsRow>
    <i className='material-icons-outlined mr-2'>person</i>
    <p>
      <b>Patient:</b>{' '}
      {patientFirstName
        ? `${patientFirstName}${patientFirstNameUsed ? ` "${patientFirstNameUsed}" ` : ' '}${patientLastName ? patientLastName : ''}`
        : '-'}{' '}
      {patientId ? `${patientId} ` : ''}
      {patientId && athenaChartUrl && (
        <a className='text-blue-600' target='_blank' href={athenaChartUrl} rel='noreferrer'>
          (Athena Chart)
        </a>
      )}
    </p>
  </DetailsRow>
);

type AppointmentAddressProps = {
  address?: string;
  addressNumber?: string;
  city?: string;
  country?: string;
  latitude?: string;
  longitude?: string;
  state?: string;
  zipCode?: string;
};

const AppointmentAddress = ({
  address,
  addressNumber,
  city,
  country,
  latitude,
  longitude,
  state,
  zipCode,
}: AppointmentAddressProps) => (
  <DetailsRow>
    <i className='material-icons-outlined'>location_on</i>
    <p>
      <b>Address:</b>{' '}
      {address ? (
        <a
          href={`https://maps.google.com/?q=${latitude},${longitude}`}
          className='text-[#0000EE]'
          target='_blank'
          rel='noreferrer'
        >
          {`${addressNumber ?? ''} ${address}, ${city ? `${city}, ` : ''}${state ?? ''} ${zipCode ?? ''}, ${country ?? ''}`}
        </a>
      ) : (
        '-'
      )}
    </p>
  </DetailsRow>
);

type DefaultRowProps = {
  icon?: string;
  label?: string;
  content?: string;
  contentClass?: string;
};

const DefaultRow = ({ icon, label, content, contentClass }: DefaultRowProps) => (
  <div className='flex items-center text-gray-5 text-base mt-6'>
    <i className='material-icons-outlined mr-2'>{icon}</i>
    <p className={contentClass}>
      <b>{label}:</b> {content || '-'}
    </p>{' '}
  </div>
);

type DetailsRowProps = {
  className?: string;
} & PropsWithChildren;

const DetailsRow = ({ className, children }: DetailsRowProps) => (
  <div className={classNames('flex items-center text-gray-5 text-base mt-6', className)}>
    {children}
  </div>
);
