import { useCallback, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { Sidebar } from 'primereact/sidebar';
import { loader } from 'react-global-loader';
import GCalIcon from '@/assets/gcal-icon.png';
import { env } from '@/utils/constants';
import AthenaIcon from '@/assets/athena-icon.png';
import {
  AppointmentParsed,
  AppointmentWithDate,
  Atria,
  ConfirmedTypes,
  PatientVisit,
  Provider,
} from '@/@types';
import { Permission, useAuthContext, useToastContext } from '@/contexts';
import { appointmentsService } from '@/services';

type PatientVisitDetailsProps = {
  visible: boolean;
  onHide: () => void;
  handleOnEditAppointments: (appointments: AppointmentParsed[]) => void;
  patientVisit: PatientVisit;
};

export const PatientVisitDetails = ({
  visible,
  onHide,
  handleOnEditAppointments,
  patientVisit,
}: PatientVisitDetailsProps) => {
  const { hasPermission } = useAuthContext();
  const { toast } = useToastContext();
  const [selectedAppointments, setSelectedAppointments] = useState<number[]>([]);
  const [selectedAllAppointments, setSelectedAllAppointments] = useState(false);
  const visitAppointmentsProvidersWithoutLiaison = useMemo(() => {
    const appointmentsProviders = new Map<number, Provider[]>();

    patientVisit.appointments.forEach((appointment) => {
      appointment.providers?.forEach((provider) => {
        if (provider.type !== 'liaison') {
          appointmentsProviders.has(appointment.id)
            ? appointmentsProviders.set(appointment.id, [
                ...appointmentsProviders.get(appointment.id)!,
                provider,
              ])
            : appointmentsProviders.set(appointment.id, [provider]);
        }
      });
    });

    return appointmentsProviders;
  }, [patientVisit.appointments]);

  const canEdit = useMemo(
    () =>
      selectedAppointments.length > 0 &&
      selectedAppointments.length <= 15 &&
      hasPermission(Permission.EDIT_APPOINTMENT),
    [hasPermission, selectedAppointments.length]
  );

  const formattedStartAndEndTime = (event: AppointmentWithDate) => {
    const start = DateTime.fromJSDate(event.date!);
    const end = DateTime.fromJSDate(event.end!);
    const formattedStart =
      start.toFormat('mm') === '00' ? start.toFormat('h a') : start.toFormat('h:mm a');
    const formattedEnd = end.toFormat('mm') === '00' ? end.toFormat('h a') : end.toFormat('h:mm a');

    return `${formattedStart} - ${formattedEnd}`;
  };

  const handleOnSelectAppointments = useCallback(
    (e: CheckboxChangeEvent) => {
      const newAppointmentsSelected = e.checked
        ? [...selectedAppointments, e.value]
        : selectedAppointments.filter((id) => id !== e.value);

      setSelectedAppointments(newAppointmentsSelected);
      setSelectedAllAppointments(
        newAppointmentsSelected.length === patientVisit.appointments.length
      );
    },
    [selectedAppointments, patientVisit.appointments]
  );

  const handleOnSelectAllAppointments = useCallback(
    (e: CheckboxChangeEvent) => {
      setSelectedAllAppointments(e.checked!);

      if (e.checked) {
        return setSelectedAppointments(patientVisit.appointments.map(({ id }) => id));
      }

      setSelectedAppointments([]);
    },
    [patientVisit.appointments]
  );

  const canMarkAsConfirmed = useMemo(
    () =>
      patientVisit.appointments.every((appt) => appt.confirmed === ConfirmedTypes.FULLY_CONFIRMED),
    [patientVisit.appointments]
  );

  const canMarkAsUnConfirmed = useMemo(
    () => patientVisit.appointments.every((appt) => appt.confirmed === ConfirmedTypes.UNCONFIRMED),
    [patientVisit.appointments]
  );

  const canMarkAsCancelled = useMemo(
    () => patientVisit.appointments.every((appt) => appt.confirmed === ConfirmedTypes.CANCELLED),
    [patientVisit.appointments]
  );

  const onChangeStatus = useCallback(
    async (status: ConfirmedTypes) => {
      const appointmentsToChange = patientVisit.appointments.filter(({ id }) =>
        selectedAppointments.includes(id)
      );

      try {
        loader.show();

        const atria: Atria.UpdateMultipleAppointments.Body['atria'] = appointmentsToChange
          .filter((a) => a.atriaAppointment)
          .map((appt) => ({
            ...appt,
            date: appt.date.toJSON(),
            roomsIds: appt.rooms?.map(({ id }) => id) || [],
            confirmed: status,
          }));

        const athena: Atria.UpdateMultipleAppointments.Body['athena'] = appointmentsToChange
          .filter((a) => !a.atriaAppointment)
          .map((appt) => ({
            ...appt,
            roomsIds: appt.rooms?.map(({ id }) => id) || [],
            confirmed: status === ConfirmedTypes.FULLY_CONFIRMED,
          }));

        await appointmentsService.updateMultipleAppointments({ atria, athena });

        toast?.current?.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Appointments updated successfully',
          life: 3000,
        });
      } catch {
        toast?.current?.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to update appointments',
          life: 3000,
        });
      } finally {
        onHide();
        loader.hide();
      }
    },
    [onHide, patientVisit.appointments, selectedAppointments, toast]
  );

  const onEditAppointments = useCallback(() => {
    const appointmentsToEdit = patientVisit.appointments.filter(({ id }) =>
      selectedAppointments.includes(id)
    );

    handleOnEditAppointments(appointmentsToEdit);
  }, [handleOnEditAppointments, patientVisit.appointments, selectedAppointments]);

  useEffect(
    () => () => {
      setSelectedAllAppointments(false);
      setSelectedAppointments([]);
    },
    [visible]
  );

  return (
    <Sidebar
      visible={visible}
      onHide={onHide}
      position='right'
      showCloseIcon={false}
      blockScroll={true}
      className='w-[500px]'
    >
      {patientVisit && (
        <>
          <header className='border-b-[1px] bottom-[var(--gray-1)] flex items-center justify-between'>
            <h1 className='text-[color:var(--moss)] text-[length:var(--font-lg)] font-bold'>
              {patientVisit.title}
            </h1>
            <Button icon='pi pi-times' rounded text aria-label='close' onClick={onHide} />
          </header>
          <main className='mt-[var(--space-regular)]'>
            {patientVisit.liaison && (
              <div className='flex'>
                <p className='font-bold mr-[var(--space-xxs)]'>Liaison:</p>
                <p>{patientVisit.liaison.name}</p>
              </div>
            )}
            {patientVisit.cmo && (
              <div className='flex'>
                <p className='font-bold mr-[var(--space-xxs)]'>CMO:</p>
                <p>{patientVisit.cmo}</p>
              </div>
            )}
            {patientVisit.appointments.length > 0 && (
              <>
                <div className='mt-4 px-3'>
                  <Checkbox
                    inputId='select-all'
                    name='select-all'
                    value={selectedAllAppointments}
                    onChange={handleOnSelectAllAppointments}
                    checked={selectedAllAppointments}
                  />
                  <label htmlFor='select-all' className='ml-3 text-sm'>
                    Select all
                  </label>
                </div>

                {patientVisit.appointments?.map((appointment) => (
                  <div
                    key={appointment.id}
                    className='relative flex items-center text-[color:var(--gray-5] text-[length:var(--font-regular)] mt-4 border-[1px] rounded-[10px] p-3'
                  >
                    <Checkbox
                      inputId={`appt-${appointment.id}`}
                      name={appointment.title}
                      value={appointment.id}
                      onChange={handleOnSelectAppointments}
                      checked={selectedAppointments.includes(appointment.id)}
                    />

                    <div className='flex flex-col items-start ml-6'>
                      <p>
                        <b>{appointment.title}</b>
                      </p>
                      <p>{formattedStartAndEndTime(appointment)}</p>
                      <p>{appointment.providerName}</p>
                      {appointment.rooms && appointment.rooms.length > 0 && (
                        <p>
                          {appointment.rooms.map((room, index) =>
                            index === 0 ? room.label : `, ${room.label}`
                          )}
                        </p>
                      )}
                      {visitAppointmentsProvidersWithoutLiaison.has(appointment.id) && (
                        <p>
                          {visitAppointmentsProvidersWithoutLiaison
                            .get(appointment.id)!
                            .map((provider, index) =>
                              index === 0 ? provider.name : `, ${provider.name}`
                            )}
                        </p>
                      )}
                    </div>

                    {(appointment.athenaAppointmentId || appointment.googleCalendarEventId) && (
                      <div className='absolute top-2 right-3'>
                        {appointment.athenaAppointmentId && appointment.googleCalendarEventId ? (
                          <div className='flex flex-col gap-1'>
                            <img src={AthenaIcon} alt='Synced to Athena' width={20} height={20} />
                            <img
                              src={GCalIcon}
                              alt='Synced to Google Calendar'
                              aria-label='Synced to Google Calendar'
                              width={20}
                              height={20}
                            />
                          </div>
                        ) : appointment.athenaAppointmentId ? (
                          <img src={AthenaIcon} alt='Synced to Athena' width={20} height={20} />
                        ) : (
                          <img
                            src={GCalIcon}
                            alt='Synced to Google Calendar'
                            width={20}
                            height={20}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}

                <div className='flex flex-col mt-8'>
                  <Button
                    label='Mark as Unconfirmed'
                    className='my-2'
                    disabled={!canEdit || canMarkAsUnConfirmed}
                    onClick={() => onChangeStatus(ConfirmedTypes.UNCONFIRMED)}
                  />

                  <Button
                    label='Mark as Confirmed'
                    className='my-2'
                    disabled={!canEdit || canMarkAsConfirmed}
                    onClick={() => onChangeStatus(ConfirmedTypes.FULLY_CONFIRMED)}
                  />

                  {env.APP_FEATURE_FLAGS.IS_TO_ENABLE_CANCELLED_STATUS && (
                    <Button
                      label='Mark as Cancelled'
                      className='my-2 bg-[color:#a25b4c]'
                      disabled={!canEdit || canMarkAsCancelled}
                      onClick={() => onChangeStatus(ConfirmedTypes.CANCELLED)}
                    />
                  )}

                  <Button
                    label='Edit'
                    className='my-2'
                    disabled={!canEdit}
                    onClick={onEditAppointments}
                    outlined
                  />
                </div>
              </>
            )}
          </main>
        </>
      )}
    </Sidebar>
  );
};
