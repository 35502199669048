import { Toast } from 'primereact/toast';
import { MutableRefObject } from 'react';

function showErrorNotification(toast: MutableRefObject<Toast> | undefined, service: string) {
  return toast?.current?.show({
    severity: 'error',
    summary: 'Error',
    detail: `Appointment was not created in ${service}. Please edit and save the appointment to try again.`,
    life: 3000,
  });
}

const messageSyncGoogleAndAthena = `
Unconfirmed and confirmed appointments will appear in the member's portal upon saving.
Appointments that have "Add to Athena" or "Add to Google Calendar" checked will be
published in the system upon saving.
`;

const titleDisclaimer = `This title is visible to members in their portal if the status is confirmed or unconfirmed.`;

export const SyncHelper = {
  showErrorNotification,
  messageSyncGoogleAndAthena,
  titleDisclaimer,
};
