import { Atria } from '@/@types';
import { StringHelper } from '@/helpers';
import { useProviders } from '@/hooks';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';

export type PatientVisitProps = {
  visit: {
    day: string;
    patientId: string;
    appointments: Atria.Appointment[];
  };
  onToggle: () => void;
};

export const PatientVisit = ({ visit, onToggle: onCollapse }: PatientVisitProps) => {
  const { providersList } = useProviders();

  const getPatientVisitName = (
    patientName: string | undefined,
    firstNameUsed: string | null | undefined,
    patientId: string
  ) => {
    if (firstNameUsed) return `${patientName} "${firstNameUsed}" ${patientId}  `;

    return `${patientName}, ${patientId}`;
  };

  const formatDate = useCallback((date: string, endDate: string) => {
    const startFormatted = DateTime.fromISO(date).toFormat('MMM d, h:mma');
    const endFormatted = DateTime.fromISO(endDate).toFormat('h:mma');

    const formattedDate = `${startFormatted} - ${endFormatted}`;
    return StringHelper.capitalizeFirstLetter(formattedDate);
  }, []);

  const [arrowCollpaseState, setArrowCollapseState] = useState<boolean>(true);

  const patientCMO = useMemo(() => {
    const cmo = providersList.find(
      (provider) =>
        provider.resourceId.split('-')[1] === String(visit.appointments[0].patientPrimaryProviderId)
    );
    return cmo ? cmo.resourceTitle : 'N/A';
  }, [providersList, visit.appointments]);

  return (
    <div className='flex justify-between'>
      <div>
        <span className='font-bold pl-[3px]'>
          {getPatientVisitName(
            visit?.appointments[0].patientName,
            visit?.appointments[0].firstNameUsed,
            visit?.patientId
          )}
        </span>
        <span className='italic'>
          {'   '}
          {patientCMO}
        </span>
        <div className='font-bold mt-[6px] pl-[3px] pb-1 text-product-grey-400'>
          {formatDate(
            visit?.appointments[0].date,
            visit?.appointments[visit?.appointments.length - 1].end
          )}
        </div>
      </div>

      <i
        className='material-icons-outlined self-center mr-2 w-2xs text-md hover:cursor-pointer'
        onClick={() => {
          setArrowCollapseState(!arrowCollpaseState);
          onCollapse();
        }}
      >
        {arrowCollpaseState ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
      </i>
    </div>
  );
};
