import { useState } from 'react';
import { AppointmentItem } from './AppointmentItem';
import { Atria } from '@/@types';

type AppointmentListProps = {
  appointmentList: {
    monthYear: string;
    patientVisits: {
      day: string;
      patientId: string;
      appointments: Atria.Appointment[];
    }[];
  }[];
  classNames?: string;
};
import styles from './CustomScroll.module.css';
import { PatientVisit } from './PatientVisit';

export const AppointmentList = ({ appointmentList, classNames = '' }: AppointmentListProps) => {
  const [hiddenPatientVisits, setHiddenPatientVisits] = useState<string[]>([]);

  const toggleHiddenPatientVisit = (visitId: string) => {
    setHiddenPatientVisits((prev) =>
      prev.includes(visitId) ? prev.filter((id) => id !== visitId) : [...prev, visitId]
    );
  };

  return (
    <div
      className={`min-h-[450px] max-h-[94%] overflow-y-auto p-5 pt-0 pr-3
        bg-[white] ${!appointmentList.length ? 'content-center' : ''}
        ${styles.customScroll} ${classNames}`}
    >
      {!appointmentList.length && (
        <p className='text-center'>
          {appointmentList === null
            ? 'Use the filters in the left side'
            : 'No appointments found for the filters selected'}
        </p>
      )}
      {!!appointmentList.length &&
        appointmentList.map(({ monthYear, patientVisits }) => (
          <div key={monthYear}>
            <h2 className='pb-4 text-base font-medium'>{monthYear}</h2>
            <ul>
              {patientVisits.map((visit) => (
                <div
                  className='text-xs rounded-md border-[1px] border-gray-1 p-[1px] pt-1 mb-[14px]'
                  key={visit.day + visit.patientId}
                >
                  <PatientVisit
                    visit={visit}
                    onToggle={() =>
                      toggleHiddenPatientVisit(monthYear + visit.day + visit.patientId)
                    }
                  />

                  <div
                    className={
                      hiddenPatientVisits.includes(monthYear + visit.day + visit.patientId)
                        ? '!hidden'
                        : ''
                    }
                  >
                    {visit.appointments.map((appointment) => (
                      <AppointmentItem item={appointment} key={appointment.id}></AppointmentItem>
                    ))}
                  </div>
                </div>
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};
